import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authService } from "./userService";


export const registerUser = createAsyncThunk("auth/register", async (userData, thunkApi) => {
    try {
        return await authService.register(userData);
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
})

export const loginUser = createAsyncThunk("auth/login", async (userData, thunkApi) => {
    try {
        return await authService.login(userData);
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
})


const initialState = {
    user: null,
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: ""
}


export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(registerUser.pending, (state) => {
            state.isLoading = true;
        }).addCase(registerUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
            state.createdUser = action.payload;
            state.message = "User Created Successfully ."
        }).addCase(registerUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.error;
        }).addCase(loginUser.pending, (state) => {
            state.isLoading = true;
        }).addCase(loginUser.fulfilled, (state, action) => {
            console.log(action.payload ,"check")
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = true;
            state.user = action.payload;
            state.message = "User Logged In Successfully ."
        }).addCase(loginUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = true;
            state.message = action.error;
        })
    }
})



export default authSlice.reducer;