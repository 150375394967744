import React from 'react'
import { useNavigate } from 'react-router-dom'

const Summary_Card = ({ title, content, value ,icon=false ,payload}) => {
    const navigate=useNavigate();
    const navigateToList =()=>{
        navigate(`${payload.to}` ,{state:payload.filter});
    }

    return (
        <>
            <div className="summary-card-container">
                <div className="inner-card-section p-3 rounded-lg gap-1 flex flex-col text-[16px] font-semibold text-color-gray-light bg-white">
                   <div className="header-section flex justify-between items-center">
                   <div className="title ">{title}</div>
                 {
                    icon&& (
                        <svg onClick={navigateToList} className='cursor-pointer' width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 1.16699L7.33333 7.00033L1.5 12.8337" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    )
                 }
                   </div>
                    <div className="count text-xl font-semibold text-color-gray-light">{value}</div>
                    <p className="content font-normal text-xs text-input-remark">{content}</p>
                  


                </div>
            </div>
        </>
    )
}

export default Summary_Card