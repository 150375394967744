


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import ReactApexChart from "react-apexcharts";
// import { baseUrl } from "../../config";
// import { useParams } from "react-router-dom";

// const RadialBarChart = () => {
//   const [datasets, setDatasets] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [selectedDataset, setSelectedDataset] = useState("");
//   const {websiteId} = useParams();

//   useEffect(() => {
//     const fetchFootprints = async () => {
//       try {
//         const response = await axios.get(`${baseUrl}/footprint/${websiteId}`);
//         const footprints = response?.data.filter(
//           (footprint) =>
//             footprint.status === "complete" || footprint.status === "stopped"
//         );

//         // Prepare datasets dynamically
//         const dynamicDatasets = footprints.reduce((acc, footprint) => {
//           acc[footprint.name] = [
//             { label: "Pass", value: footprint.successCount, color: "#12B76A" },
//             { label: "Fail", value: footprint.failureCount, color: "#F04438" },
//           ];
//           return acc;
//         }, {});

//         setDatasets(dynamicDatasets);

//         // Set the first dataset as default
//         const firstDataset = Object.keys(dynamicDatasets)[0];
//         setSelectedDataset(firstDataset || "");
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching footprints:", error);
//         setLoading(false);
//       }
//     };

//     fetchFootprints();
//   }, [websiteId]);

//   // Update chart data whenever datasets or selectedDataset changes
//   const data = datasets[selectedDataset] || [];
//   const seriesData = data.map((item) => item.value);
//   const labels = data.map((item) => item.label);
//   const colors = data.map((item) => item.color);
//   const totalRuns = seriesData.reduce((sum, val) => sum + val, 0);

//   const chartData = {
//     series: seriesData,
//     options: {
//       chart: {
//         type: "donut",
//         height: 350,
//       },
//       labels: labels,
//       colors: colors,
//       legend: {
//         position: "right",
//         markers: {
//           radius: 12,
//         },
//         formatter: function (label, opts) {
//           return `${label}: ${opts.w.globals.series[opts.seriesIndex]}`;
//         },
//       },
//       dataLabels: {
//         enabled: true,
//         formatter: function (val, opts) {
//           return `${parseInt(val)}%`;
//         },
//         dropShadow: {
//           enabled: true,
//           top: 3,
//           left: 0,
//           blur: 4,
//           opacity: 0.24,
//         },
//       },
//       plotOptions: {
//         pie: {
//           donut: {
//             size: "65%",
//             labels: {
//               show: true,
//               name: {
//                 show: true,
//                 fontSize: "16px",
//                 color: "#333",
//                 fontWeight: 600,
//                 offsetY: -10,
//               },
//               value: {
//                 show: true,
//                 fontSize: "20px",
//                 color: "#333",
//                 fontWeight: 600,
//                 offsetY: 10,
//               },
//               total: {
//                 show: true,
//                 label: "Total",
//                 fontSize: "16px",
//                 fontWeight: 600,
//                 color: "#666",
//                 formatter: function () {
//                   return `${totalRuns}`;
//                 },
//               },
//             },
//           },
//         },
//       },
//       tooltip: {
//         enabled: true,
//         y: {
//           formatter: function (val) {
//             return `${val} Tests`;
//           },
//         },
//       },
//       stroke: {
//         show: false,
//       },
//     },
//   };

//   const handleDatasetChange = (e) => {
//     setSelectedDataset(e.target.value);
//   };

//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   return (
//     <div className="p-4">
//       <div className="top-section flex justify-between items-center">
//         <h2 className="text-lg font-semibold">Analysis</h2>
//         <select
//           id="dataset"
//           value={selectedDataset}
//           onChange={handleDatasetChange}
//           className="focus:outline-none p-2 w-3/4 text-sm cursor-pointer"
//         >
//           {Object.keys(datasets).map((key) => (
//             <option value={key} key={key}>
//               {key}
//             </option>
//           ))}
//         </select>
//       </div>

//       <div className="mt-4">
//         <ReactApexChart
//           options={chartData.options}
//           series={chartData.series}
//           type="donut"
//           height={350}
//         />
//       </div>
//     </div>
//   );
// };

// export default RadialBarChart;




import React, { useState, useEffect } from "react";
import axios from "axios";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Sector } from "recharts";
import { baseUrl } from "../../config";
import { useParams } from "react-router-dom";
import emptyChart from "../Images/skyrocket.png"
import Empty_Screen_Card from "../Card/Empty_Screen_Card";

const CustomActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} fontWeight="bold">
        {payload.label}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
        {`Count: ${value}`}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const RadialBarChart = () => {
  const [datasets, setDatasets] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedDataset, setSelectedDataset] = useState("");
  const { websiteId } = useParams();

  useEffect(() => {
    const fetchFootprints = async () => {
      try {
        const response = await axios.get(`${baseUrl}/footprint/${websiteId}`);
        const footprints = response?.data.filter(
          (footprint) =>
            footprint.status === "complete" || footprint.status === "stopped"
        );

        const dynamicDatasets = footprints.reduce((acc, footprint) => {
          acc[footprint.name] = [
            { label: "Pass", value: footprint.successCount, color: "#12B76A" },
            { label: "Fail", value: footprint.failureCount, color: "#F04438" },
          ];
          return acc;
        }, {});

        setDatasets(dynamicDatasets);
        const firstDataset = Object.keys(dynamicDatasets)[0];
        setSelectedDataset(firstDataset || "");
        setLoading(false);
      } catch (error) {
        console.error("Error fetching footprints:", error);
        setLoading(false);
      }
    };

    fetchFootprints();
  }, [websiteId]);

  const data = datasets[selectedDataset] || [];
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const handleDatasetChange = (e) => {
    setSelectedDataset(e.target.value);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="p-3">
    {
      datasets.length> 0  ? (
        <>
          <div className="top-section flex justify-between items-center">
        <h2 className="text-lg font-semibold">Analysis</h2>
        <select
          id="dataset"
          value={selectedDataset}
          onChange={handleDatasetChange}
          className=" p-2 w-1/4 text-sm cursor-pointer"
        >
          {Object.keys(datasets).map((key) => (
            <option value={key} key={key}>
              {key}
            </option>
          ))}
        </select>
      </div>

      <div className="mt-4">
        <ResponsiveContainer width="100%" height={300}>  {/** 350 initially */}
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={CustomActiveShape}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              dataKey="value"
              onMouseEnter={onPieEnter}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
        </>
      ):   <div className="empty-screen py-12 bg-white rounded-lg">
      <Empty_Screen_Card
        image={emptyChart}
        button={false}
        mainContent="Analysis in Process"
        subContent={
          <>
            Analysis will show up once the tests  <br />
            in process are completed!
          </>
        }
      />
    </div>
    }


   
    </div>
  );
};

export default RadialBarChart;
