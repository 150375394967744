import React from "react";
import ReactDOM from "react-dom";

const SuccessToast = ({ successValue, activeSuccess, setActiveSuccess }) => {


  return ReactDOM.createPortal(
    <div
      id="toast-success"
      class="flex items-center fixed border-b border-b-[#A6F4C5]  max-w-lg  py-3 px-12  min-h-[44px] bg-success rounded-lg "
      role="alert"
      style={{
        zIndex: 5000,
        top: "5%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "546px",

      }}
    >

      <p class=" text-sm text-white">{successValue}</p>

    </div>,
    document.getElementById("toast-root-success")
  );
};

export default SuccessToast;
