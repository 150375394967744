import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'; // Use this if you're using React Router for navigation



const Button = ({
    id,
    text,
    onClick,
    to,
    type = 'button',
    className = '',
    disabled = false,
    primary = true,
}) => {
    // Conditional class name based on the primary prop
    const buttonClass = primary
        ? `px-5 py-3 rounded bg-primary-button font-semibold text-sm text-white hover:bg-hover transition ease-out duration-300  disabled:bg-disable-button disabled:cursor-not-allowed ${className}`
        : `px-5 py-3 rounded bg-white text-sm text-color-gray-light hover:bg-secondary-button border border-secondary-border transition ease-out duration-300 disabled:opacity-50 disabled:bg-secondary-button disabled:text-disable-secondary font-semibold disabled:cursor-not-allowed ${className}`;

    // If `to` is provided, render a Link
    if (to) {
        return (
            <Link to={to}>
                <button
                    id={id}
                    type={type}
                    onClick={onClick}
                    disabled={disabled}
                    className={buttonClass}
                >
                    {text}
                </button>
            </Link>
        );
    }

    // Render a standard button
    return (
        <button
            id={id}
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={buttonClass}
        >
            {text}
        </button>
    );
};


export default Button