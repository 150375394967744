import React, { useEffect, useState } from "react";
import FailToast from "../Toast/FailToast";
import { baseUrl } from "../../config";
import axios from "axios"
import SuccessToast from "../Toast/SuccessToast";
import usePushNotifications from "../../hooks/usePushNotifications";
import Alert_Card from "../Card/Alert_Card";


const Notification = () => {
  const [fail, setFail] = useState([]);
  const [billing, setBilling] = useState([]);
  const [activeFail, setActiveFail] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSubscribe, setIsSubscribe] = useState()
  const [isEmailEnabled, setIsEmailEnabled] = useState(true)
  const { initializePushNotifications } = usePushNotifications();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showPop, setShowPop] = useState(false); // State to control Alert_Card visibility
  const [selectedOptionId, setSelectedOptionId] = useState(null); // To track which toggle was clicked
  const [selectedEvent, setSelectedEvent] = useState(null); // To store the event for later use
  const [popText, setPopText] = useState(null)
  const [loading ,setLoading] = useState(false);
  

  const [billingToggleStates, setBillingToggleStates] = useState({
    billing_email: true,
    billing_sms: false,
  });
  const [failToggleStates, setFailToggleStates] = useState({
    fail_notification_email: true,
    fail_notification_push: true
  });
  const notificationOptions = [
    { label: "Email", id: "fail_notification_email" },
    { label: "Push ", id: "fail_notification_push" },
  ];


  const handlePopup = () => {
    setShowPop(true);
  };



  const fetchUserSubscription = async () => {
    try {
      const userId = localStorage.getItem("userId")
      const response = await axios.get(`${baseUrl}/pushNotification/subscription-status/${userId}`);
      console.log("response from pushNotification", response.data.isSubscribed)
      setIsSubscribe(response?.data?.isSubscribed)



    } catch (error) {
      console.log('Error fetching PushNotification:', error.response);

    }
    return;
  };


  const deleteUserSubscription = async () => {
    const userId = localStorage.getItem("userId")
    try {
      const response = await axios.delete(
        `${baseUrl}/pushNotification/delete-subscription/${userId}`
      );
      setIsSubscribe(false)
      console.log("response from deleteSubscription", response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
    return;

  }

  const fetchUserEmailEnabled = async () => {
    try {
      const userId = localStorage.getItem("userId")
      const response = await axios.get(`${baseUrl}/onboard/${userId}`);
      console.log("response from Onboard data from notification", response?.data?.onboardData[0]?.user?.isEmailEnabled);
      setIsEmailEnabled(response?.data?.onboardData[0]?.user?.isEmailEnabled)

    } catch (error) {
      console.log("Error in fetching data", error.response);

    }
  };

  const updateUserEmailEnabled = async (isEmailEnabled) => {
    const userId = localStorage.getItem("userId")
    try {
      const response = await axios.patch(
        `${baseUrl}/user/${userId}/notification`, { isEmailEnabled }
      );
      console.log("response from updateUserEmailEnabled", response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
    return;

  }


  // const handleChangeNotification = async (event, id) => {

  //   if (!event.target.checked) {
  //     const confirmation = window.confirm(
  //       `Are you sure you want to turn off ${id === "fail_notification_email" ? "Email" : "Push"} notifications?`
  //     );

  //     if (!confirmation) {
  //       return; // Exit if user cancels the action
  //     }
  //   }






  //   if (!isSubscribe && id === "fail_notification_push") {
  //     await initializePushNotifications()
  //     await fetchUserSubscription()


  //   }

  //   if (isSubscribe && id === "fail_notification_push") {
  //     await deleteUserSubscription()
  //     // console.log("isSubScribed",isSubscribed);
  //   }

  //   if (id === "fail_notification_email") {
  //     await updateUserEmailEnabled(!isEmailEnabled)
  //     await fetchUserEmailEnabled()

  //   }

  //   setFailToggleStates((prevState) => ({
  //     ...prevState,
  //     [id]: event.target.checked,
  //   }));


  // };








  const handleChangeNotification = async (event, id) => {

    if (!event.target.checked && !selectedEvent && !selectedOptionId) {
      // If toggling off, show Alert_Card

      setSelectedOptionId(id);
      setSelectedEvent(event);
      setPopText(id === "fail_notification_email" ? "email" : "push")
      setShowPop(true);
      return; // Exit without updating state immediately
    }

    // Proceed with toggling on or confirmed action
    if (!isSubscribe && id === "fail_notification_push") {
      await initializePushNotifications();
      await fetchUserSubscription();
    }

    if (isSubscribe && id === "fail_notification_push") {
      await deleteUserSubscription();
    }

    if (id === "fail_notification_email") {
      await updateUserEmailEnabled(!isEmailEnabled);
      await fetchUserEmailEnabled();
    }

    // Update toggle state
    setFailToggleStates((prevState) => ({
      ...prevState,
      [id]: event.target.checked,
    }));
  };


  const handleConfirmPopup = async () => {
    if (selectedOptionId && selectedEvent) {
      console.log("calling handleConfirm", selectedEvent.target.checked, selectedOptionId)
      await handleChangeNotification(selectedEvent, selectedOptionId); // Proceed with action
    }
    handleClosePopup(); // Close popup after confirmation
  };

  const handleClosePopup = () => {
    setShowPop(false); // Close popup without making changes
    setSelectedOptionId(null); // Clear stored option ID
    setSelectedEvent(null); // Clear stored event
  };



  const handleChangeBilling = (event, id) => {
    setBillingToggleStates((prevState) => ({
      ...prevState,
      [id]: event.target.checked,
    }));
  };



  const handleFilterTrueValues = () => {

    if (
      Array.isArray(billingToggleStates) ||
      typeof billingToggleStates === "object"
    ) {
      const trueBillingToggles = Object.entries(billingToggleStates)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
      setBilling(trueBillingToggles);
    }

    if (
      Array.isArray(failToggleStates) ||
      typeof failToggleStates === "object"
    ) {
      const trueFailToggles = Object.entries(failToggleStates)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
      setFail(trueFailToggles);

    }
  };

  const handleNotification = () => {
    console.log("fail from handleNotification", fail);
    if (fail.length == 0) {
      setActiveFail(true);
      setMsg(
        "Oops! You need to have at least 1 notification enabled for Foot Print failure notifications."
      );
    } else {
      setActiveSuccess(true);
      setMsg("Awesome! Notifications settings updated successfully");
    }
  };
  const handleBilling = () => {
    console.log("fail from handleBilling", billing);

    if (billing.length == 0) {
      setActiveFail(true);
      setMsg(
        " Oops!You need to have at least 1 notification enabled for billing reminders."
      );
    } else {
      setActiveSuccess(true);
      setMsg("Awesome! Notifications settings updated successfully");
    }
  };
  useEffect(() => {
    handleFilterTrueValues();
  }, [failToggleStates, billingToggleStates]);


  useEffect(() => {
    fetchUserSubscription()
    fetchUserEmailEnabled()
  }, [])

  // console.log("isSubscribe", isSubscribe)
  // useEffect(() => {
  //   setFailToggleStates(prevState => ({
  //     ...prevState,
  //     fail_notification_push: isSubscribe
  //   }));


  // }, [isSubscribe])


  useEffect(() => {
    setFailToggleStates(prevState => ({
      ...prevState,
      fail_notification_push: isSubscribe,
      fail_notification_email: isEmailEnabled
    }));


  }, [isSubscribe, isEmailEnabled])

  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 3000);
    }
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
      }, 2000);
    }
  }, [activeFail, activeSuccess]);

  return (


    <div class="h-full ">
      {showPop && (
        <>
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>{" "}
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <Alert_Card
              // mainContent={`Are you sure you want to switching off ${popText} notifications?`}
              mainContent={
                <>
                  Are you sure you want to switching off <br />
                  {popText} notifications?
                </>
              }
              onClose={handleClosePopup}
              handleNotification={handleConfirmPopup}
              type={"notification"}
              // smallContent={`You are about to switch off ${popText} notifications. We will not send you any ${popText} if your tests fail.`}
              smallContent={<>
                You are about to switch off {popText} notifications. We will not send<br />
                you any {popText} if your tests fail.
              </>}
            />
          </div>
        </>
      )}
      <div className='bg-white h-full  border border-layout-border shadow-[0px_1px_2px_0px_#0000000D] rounded-lg py-6 px-10'>
        <div className='font-semibold text-3xl'>
          Notifications
        </div>
        <div className='font-medium text-sm text-color-gray-light pt-1'>
          Manage your notification detail from this section
        </div>
        <div className="mt-8">
          {notificationOptions.map((option) => (
            <div
              key={option.id}
              className="flex items-center justify-between mt-4 p-3 rounded-md border border-link-border"
            >
              <div className="flex flex-col space-y-[6px]">
                <span className="text-color-gray-light font-semibold text-base">
                  {option.label} Notifications
                </span>
                <span className="font-normal text-xs text-input-remark">
                  We’ll send you {option.label.toLowerCase()} notifications whenever your tests fail.
                </span>
              </div>

              <div className="flex justify-center items-center space-x-2"
                onClick={(event) =>
                  handleChangeNotification(
                    { target: { checked: !failToggleStates[option.id] } },
                    option.id
                  )
                }


              >
                {failToggleStates[option.id] ?

                  
                  <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer">
                    <g clip-path="url(#clip0_2720_52341)">
                      <rect width="36" height="20" rx="10" fill="#875BF7" />
                      <g filter="url(#filter0_dd_2720_52341)">
                        <circle cx="26" cy="10" r="8" fill="white" />
                      </g>
                    </g>
                    <defs>
                      <filter id="filter0_dd_2720_52341" x="12" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_2720_52341" />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.06 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2720_52341" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect2_dropShadow_2720_52341" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="4" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="effect1_dropShadow_2720_52341" result="effect2_dropShadow_2720_52341" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2720_52341" result="shape" />
                      </filter>
                      <clipPath id="clip0_2720_52341">
                        <rect width="36" height="20" rx="10" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  :
                  <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer">
                    <g clip-path="url(#clip0_2720_52344)">
                      <rect width="36" height="20" rx="10" fill="#E5E7EB" />
                      <g filter="url(#filter0_dd_2720_52344)">
                        <circle cx="10" cy="10" r="8" fill="white" />
                      </g>
                    </g>
                    <defs>
                      <filter id="filter0_dd_2720_52344" x="-4" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_2720_52344" />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.06 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2720_52344" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect2_dropShadow_2720_52344" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="4" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.1 0" />
                        <feBlend mode="normal" in2="effect1_dropShadow_2720_52344" result="effect2_dropShadow_2720_52344" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2720_52344" result="shape" />
                      </filter>
                      <clipPath id="clip0_2720_52344">
                        <rect width="36" height="20" rx="10" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                }
              </div>
            </div>
          ))}
        </div>




      </div>




    </div>
  );
};

export default Notification;
