import React, { useState, useEffect } from "react";
import axios from "axios";
import FloatingInput from "../Input/FloatingInput";
import { baseUrl } from "../../config";
import Intercom from "@intercom/messenger-js-sdk";
import Popup_Card from "./Popup_Card";
import usePushNotifications from "../../hooks/usePushNotifications";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { useParams ,useNavigate} from "react-router-dom";
const Test_Home_Card = ({ websiteId }) => {

    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [websiteUrl, setWebsiteUrl] = useState();
    const [websiteData, setWebsiteData] = useState()
    const [checked, setChecked] = useState(false);
    const { initializePushNotifications, isSubscribed } = usePushNotifications();

    // Determine which URL to use based on user role
    const [user, setUser] = useState();



    window.intercomSettings = {
        app_id: "x5w0eskb",
        custom_launcher_selector: "#help",
    };
    const [formData, setFormData] = useState({
        footprintName: "",
        footprintUrl: "",
        tags: "",
        websiteId: websiteId,
        tagsArray: [],
    });

    const [showPop, setShowPopup] = useState(false);

    const handlePopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const urlToSend = user?.role === "admin" ? formData?.footprintUrl : websiteUrl;
    console.log("WebsiteId passed in test home card", formData.websiteId)
    useEffect(() => {
        const messageListener = (event) => {
            if (event.source !== window) return;
            if (event.data.type && event.data.type === "FROM_EXTENSION") {
                console.log("Message from extension:", event.data.data);
                // Handle the message from the extension if needed
            }
        };

        window.addEventListener("message", messageListener);

        return () => {
            window.removeEventListener("message", messageListener);
        };
    }, []);









    const checkPopUp = async () => {
        try {

            if (!isSubscribed) { console.log("ye shi hai"); await initializePushNotifications() };

            // Check response and handle accordingly
            if (checked) {
                createFootprint();

            } else {
                handlePopup();

            }
        } catch (error) {
            console.error("Error updating popup status:", error);
        }
    };


    console.log(
        "Start tracking message sent to extension",
        formData,
        websiteUrl
    );

    const createFootprint = async () => {
        // console.log("mai data hu ", formData);
        const token = localStorage.getItem("Token");
        const userId = localStorage.getItem("userId");

        // Send message to content script to start tracking
        window.postMessage(
            {
                type: "START_TRACKING",
                url: formData.footprintUrl,
                userId: userId,
                websiteId: formData.websiteId,
                footprintName: formData.footprintName,
                tags: formData.tags,
                websiteUrl: urlToSend,
                tagsArray: formData.tagsArray,
                thirdPartyUrls: websiteData?.thirdPartyUrl,
            },
            "*"
        );

        console.log(
            "Start tracking message sent to extension",
            formData,
            websiteUrl
        );
    };

    const fetchUserWebsite = async () => {
        const userId = localStorage.getItem("userId");
        try {
            const response = await axios.get(`${baseUrl}/user/${userId}`);
            console.log("fetching user for checking admin",response.data)
            setUser(response.data);

        } catch (error) {
            console.log("Error in fetching data", error.response);
        }
    };

    const fetchWebsiteByWebsiteId = async () => {
        try {

            const response = await axios.get(`${baseUrl}/websites/${websiteId}`);
            console.log("response from website url", response?.data?.url);
            setWebsiteUrl(
                response?.data?.url
            );
            setChecked(response?.data.showPopUp);
            setWebsiteData(response?.data)

        } catch (error) {
            console.log("Error in fetching data", error.response);
        }
    };




    useEffect(() => {
        fetchUserWebsite();
        fetchWebsiteByWebsiteId();
    }, []);


    useEffect(() => {
        if (user?.role === "admin") {

            setIsButtonEnabled(true);
        } else {
            const isValid = formData.footprintUrl.includes(websiteUrl);
            console.log("checking website valied", isValid)
            setIsButtonEnabled(isValid);
        }
    }, [websiteUrl, formData.footprintUrl, user]);


    

    return (


        <>
            <div className="container ">
                <div className="wrapper  w-full">

                    <div className="top-section flex justify-between items-center mx-4 my-4">
                        <div className="content text-color-gray-light font-semibold text-xl ">
                            <h3 className="text-3xl">New Test</h3>
                            <p className="text-sm font-medium ">Start by filling out this form and create a new test</p>
                        </div>
                    </div>

                    <Input
                        formData={formData}
                        setFormData={setFormData}
                        isEditable={true}
                        isReadOnly={false}
                        isButtonEnabled={isButtonEnabled}
                    />

                    {showPop && (
                        <>
                            <div className="fixed inset-0 bg-black opacity-50 z-40"></div>{" "}
                            {/* Overlay */}
                            <div className="fixed inset-0 flex items-center justify-center z-50">
                                <Popup_Card
                                    formData={formData}
                                    onClose={handleClosePopup}
                                    urlToSend={urlToSend}
                                />
                            </div>
                        </>
                    )}

                    <div className="my-3">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="ml-4 flex items-center gap-6 my-2 ">
                                    <Button id="button_create_test" text="Create Test" onClick={checkPopUp}
                                        disabled={!isButtonEnabled} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>



    );
};

export default Test_Home_Card;
