import React from 'react'
import Test_All_List from '../components/Footprint/Test_All_List'

const TestListPage = () => {
  return (
    <>
    <Test_All_List/>
    </>
  )
}

export default TestListPage