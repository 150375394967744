import React from 'react'
import Test_Schedule from '../components/Footprint/Test_Schedule'

const TestDetailsPage = () => {
  return (
    <>
      <div className="guides">
        <div className="inner-guides">
          <Test_Schedule/>
        </div>
      </div>
    </>
  )
}

export default TestDetailsPage