import React, { useState,useEffect } from "react";
import Test_Schedule_Card from "../Card/Test_Schedule_Card";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import Test_Home_Card from "../Card/Test_Home_Card";
import axios from 'axios';
import Summary_Card from "../Card/Summary_Card";

const Test_Summary = () => {
  const { footprintId } = useParams();

 

  const [data, setData] = useState();
  let pendingRuns =
    data?.occurrences - (data?.successCount + data?.failureCount);

  useEffect(() => {
    // Fetch data from the API and set the input field values
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/footprint/foot/${footprintId}`
        );
        const footprint = response.data;
        setData(footprint);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
 
 

 



 





  return (
    <>
      <div className="header-section">

        <div className="info border border-layout-border p-6 rounded-lg bg-layout shadow-[0px_1px_2px_0px_#0000000D]">
      

          <div className="setup-guide">
            <div className="inner-setup-guide text-color-gray-light flex justify-between items-center gap-6">
              <Summary_Card  title="Total Runs" value=  {data?.successCount + data?.failureCount} content="The total number of times this test was run" />
              <Summary_Card  title="Pending Runs" value={pendingRuns} content="The pending number of runs this test is pending" />
              <Summary_Card  title="Pass Runs" value={data?.successCount} content="The total number of times this test was passed" />
              <Summary_Card  title="Failed Runs" value={data?.failureCount} content="The total number of times this test was failed" />

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Test_Summary;
