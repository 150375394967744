import React from 'react'
import ApexChart from './ApexChart'
import RadialBarChart from './RadialBarChart'
import ChartComponent from './CharComponent'

const DonutChart = ({figures}) => {
  return (
    <div>
      <ApexChart  figures={figures}/>
      {/* <RadialBarChart/> */}
      {/* <ChartComponent/> */}
      {/* Grid layout with responsive columns */}
     
    </div>
  )
}

export default DonutChart