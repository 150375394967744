import { configureStore } from "@reduxjs/toolkit";

import userReducer from '../features/user/userSlice';
import testReducer from '../features/tests/testSlice';


export const store =configureStore({
    reducer:{
        user:userReducer,
        tests:testReducer
    }
})


export default store;