import React from 'react'
import Button from '../Button/Button'

const Alert_Card = ({ onClose, handleNotification, handleDelete, handlePause, mainContent, smallContent, type }) => {
    return (
        <>
            <div className="alert-card-container ">
                <div className="inner-alert-container shadow-[0px_8px_8px_-4px_#10182808] rounded-xl flex flex-col p-6 gap-8 bg-white">
                    <div className="top-section flex flex-col gap-5">
                        <div className="logo-container">
                            {
                                type === 'pause' || type === "notification" ? (
                                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
                                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FFFAEB" stroke-width="8" />
                                        <path d="M27.9998 24.0002V28.0002M27.9998 32.0002H28.0098M26.2898 18.8602L17.8198 33.0002C17.6451 33.3026 17.5527 33.6455 17.5518 33.9947C17.5508 34.3439 17.6413 34.6873 17.8142 34.9907C17.9871 35.2941 18.2365 35.547 18.5375 35.7241C18.8385 35.9012 19.1806 35.9964 19.5298 36.0002H36.4698C36.819 35.9964 37.1611 35.9012 37.4621 35.7241C37.7631 35.547 38.0124 35.2941 38.1854 34.9907C38.3583 34.6873 38.4488 34.3439 38.4478 33.9947C38.4468 33.6455 38.3544 33.3026 38.1798 33.0002L29.7098 18.8602C29.5315 18.5663 29.2805 18.3233 28.981 18.1547C28.6814 17.9861 28.3435 17.8975 27.9998 17.8975C27.656 17.8975 27.3181 17.9861 27.0186 18.1547C26.7191 18.3233 26.468 18.5663 26.2898 18.8602Z" stroke="#DC6803" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                ) : (
                                    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2" />
                                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8" />
                                        <path d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                )
                            }




                        </div>
                        <div className="content-section text-auth-label text-xl font-medium flex flex-col gap-2">
                            <div className='main-content'>  {mainContent}</div>

                            <div className="small-content text-sm font-medium text-alert-small-content">
                                {smallContent}
                            </div>

                        </div>
                    </div>
                    <div className="bottom-section">
                        <div className="delete-button flex justify-between items-center gap-3">
                            <button className="border border-secondary-border bg-white text py-3 px-6 w-full   text-color-gray-light  hover:bg-secondary-button transition ease-out duration-300 text-base font-semibold  rounded" onClick={type === 'pause' ? handlePause : type === "delete" ? handleDelete : type === "notification" ? handleNotification : null}>{type === 'pause' ? "Pause" : type === "delete" ? "Delete" : type === "notification" ? "Confirm" : null}</button>
                            <button className="border border-secondary-border text py-3 px-6  text-base font-semibold w-full hover:bg-hover transition ease-out duration-300 text-white bg-primary-button rounded" onClick={onClose}>Cancel</button>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Alert_Card