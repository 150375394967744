import React from 'react'
import Button from '../Button/Button'

const Empty_Screen_Card = ({image, mainContent,subContent , to, buttonName ,onclick ,button=true}) => {
    return (
        <><div className="empty-container flex flex-col gap-4 justify-center items-center">
            <div className="top-section">
                <div className="image-section">
                    <div className="image w-[144px] h-full ">

                        <img src={image} alt="" className="w-full h-full object-cover" />
                    </div>
                </div>
            </div>
            <div className="bottom-section flex flex-col gap-6 justify-center items-center">
                <div className="content-heading flex flex-col gap-2 ">
                    <div className="main-content font-bold text-3xl text-color-gray-light">{mainContent}</div>
                    <div className="sub-content font-medium text-sm text-footer text-center">{subContent}</div>
                </div>
                {
                        button ===true ?    
                <div className="button-section justify-center items-center">
                                  <Button text={buttonName} to={to} onClick={onclick} />

                </div>

: ""
}
            </div>
        </div>
        </>
    )
}

export default Empty_Screen_Card