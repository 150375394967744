

import React, { useEffect, useRef, useState } from "react";

import { FeedbackFish } from "@feedback-fish/react";
import axios from "axios";
import Intercom from "@intercom/messenger-js-sdk";
import { baseUrl } from "../../config";
import { Link, useNavigate } from "react-router-dom";
const Nav = () => {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hideResult, setHideResult] = useState(false);
  const searchAreaRef = useRef(null);
  Intercom({
    app_id: "x5w0eskb",
    user_id: user?._id,
    name: user?.username,
    email: user?.email,
    created_at: user?.createdAt,
  });

  const fetchUserById = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user/${userId}`);
      console.log("response from userDetails", response.data);
      setUser(response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  const performSearch = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/search?q=${searchQuery}&userId=${userId}`
      );
      console.log("searchResult", response.data);
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        performSearch();
      } else {
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);
  useEffect(() => {
    fetchUserById();
  }, []);

  const handleClickResult = (result) => {
    const data = {
      footprintId: result?._id,
      websiteId: result?.website,
    };
    navigate(`/footprint-list`, { state: data });
    setHideResult(true);
    setSearchQuery(result.name);
  };

  const handleSearchQuery = (e) => {
    setHideResult(!hideResult);
    setSearchQuery(e.target.value);
  };



  const TagsDisplay = ({ tags }) => {
    // Join the tags array into a single string with spaces
    const joinedTags = tags.join(" , ");

    return (
      <p className="text-xs text-gray-600 truncate">
        {joinedTags}
        <span className="ml-2 text-gray-300">tags</span>
      </p>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchAreaRef.current &&
        !searchAreaRef.current.contains(event.target)
      ) {
        setHideResult(true); // Hide results if clicked outside search area
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchAreaRef]);

  function getInitials(username) {
    if (!username || typeof username !== "string") {
      return ""; // Return an empty string for invalid input
    }

    const parts = username.trim().split(" "); // Split the username by spaces

    if (parts.length > 1) {
      // If there are multiple parts, return the first and last parts combined
      return parts[0][0].toUpperCase() + parts[parts.length - 1][0].toUpperCase();
    } else {
      // If there's only one part, return the first capital letter
      return username[0].toUpperCase();
    }
  }


  const websiteId =localStorage.getItem("selectedWebsiteId");

  return (
    <>
      <div className="bg-button-bg-nav sticky top-0 z-50">
        <div className="wrapper">
          <div className="navbar bg-white px-20 py-3  border-b border-link-border">
            <div className="inner-wrapper flex justify-between items-center ">
              <div className="logo">

                  <div className="logo ">
                  <Link to={`/${websiteId}/test-list`} className="cursor-pointer  flex items-center  gap-2">

                    <svg width="33" height="33" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.54286 3.77143H60.3429V61.2857H7.54286V3.77143Z" fill="white" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 11.3143C0 5.06558 5.06558 0 11.3143 0H54.6857C60.9344 0 66 5.06558 66 11.3143V54.6857C66 60.9344 60.9344 66 54.6857 66H11.3143C5.06558 66 0 60.9344 0 54.6857V11.3143ZM14.1429 15.1777C14.1429 13.1486 14.6807 11.6831 15.7563 10.7813C16.8768 9.87949 18.3782 9.42857 20.2605 9.42857H45.7396C47.7564 9.42857 49.2578 9.92458 50.2438 10.9166C51.3195 11.8635 51.8573 13.2839 51.8573 15.1777C51.8573 17.1167 51.3195 18.5596 50.2438 19.5065C49.2578 20.4985 47.7564 20.9945 45.7396 20.9945H39.8909C37.7844 21.0396 35.9917 21.2876 34.5127 21.7385C33.0337 22.1894 31.7788 22.7982 30.748 23.5647C29.762 24.3313 28.9776 25.2331 28.395 26.2702C27.8124 27.3073 27.3866 28.412 27.1177 29.5844V20.9945H20.2605C18.2437 20.9945 16.7423 20.4985 15.7563 19.5065C14.6807 18.5596 14.1429 17.1167 14.1429 15.1777ZM38.8118 49.3343C38.8118 50.3263 38.6998 51.2732 38.4757 52.175C38.2964 53.0318 37.9827 53.7983 37.5345 54.4747C37.0863 55.106 36.4813 55.6245 35.7194 56.0303C35.0023 56.3911 34.1059 56.5714 33.0303 56.5714C31.9547 56.5714 31.0359 56.3911 30.274 56.0303C29.5121 55.6245 28.8846 55.106 28.3916 54.4747C27.9434 53.7983 27.6073 53.0318 27.3832 52.175C27.2039 51.2732 27.1143 50.3263 27.1143 49.3343V35.401C27.1143 31.7486 28.1451 28.9304 30.2067 26.9464C32.2684 24.9624 35.1367 23.9027 38.8118 23.7674V49.3343Z" fill="#5049AC" />
                    </svg>
                    
                    <span className="font-bold text-color-gray-light ">TestTube</span>
                    </Link>

                  </div>

              </div>
              {/* 
              <div
                className="relative  mx-20"
                style={{ width: "60%" }}
                ref={searchAreaRef}
              >
                <div className="relative flex items-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.8688 15.4573C19.3126 12.3188 19.0918 7.77813 16.2067 4.89295C13.0825 1.76876 8.01714 1.76876 4.89295 4.89295C1.76876 8.01714 1.76876 13.0825 4.89295 16.2067C7.77813 19.0918 12.3188 19.3126 15.4573 16.8688C15.4708 16.8841 15.4849 16.8991 15.4996 16.9138L19.7422 21.1564C20.1327 21.5469 20.7659 21.5469 21.1564 21.1564C21.5469 20.7659 21.5469 20.1327 21.1564 19.7422L16.9138 15.4996C16.8991 15.4849 16.8841 15.4708 16.8688 15.4573ZM14.7924 6.30716C17.1356 8.65031 17.1356 12.4493 14.7924 14.7924C12.4493 17.1356 8.65031 17.1356 6.30716 14.7924C3.96402 12.4493 3.96402 8.65031 6.30716 6.30716C8.65031 3.96402 12.4493 3.96402 14.7924 6.30716Z"
                      fill="#E0E0E0"
                    />
                  </svg>
             

                  <input
                    type="text"
                    placeholder="Search to find your websites, tests, results and more"
                    value={searchQuery}
                    onChange={(e) => handleSearchQuery(e)}
              
                    style={{
                      backgroundColor: 'rgba(30, 30, 30, 0.9)',
                  
                    }}
                    className="w-full pl-10 pr-4 py-1 text-white  border border-[#393939] text-sm tracking-wider focus:outline-none   rounded-lg placeholder-white"

                  />





                </div>

                {!hideResult &&
                  (isLoading ||
                    searchResults?.length > 0 ||
                    (searchQuery && !isLoading)) && (
            
                    <div
                      id=""
                      className="absolute cursor-pointer z-50 w-full mt-1 bg-white shadow-lg rounded-lg overflow-y-auto   custom-scrollbar"
                      style={{ maxHeight: "300px" }}
                    >
                      <div className="py-2 px-4 h-full ">
                        {isLoading && (
                          <p className="text-gray-600 text-sm py-1">
                            Loading...
                          </p>
                        )}
                        {!isLoading &&
                          searchResults &&
                          searchResults?.length > 0 && (
                            <ul className="space-y-0.5">
                              {searchResults.map((result, index) => (
                                <li
                                  key={index}
                                  onClick={() => handleClickResult(result)}
                                  className="border-b border-gray-200 last:border-b-0 py-1 hover:bg-gray-100  transition-colors duration-150"
                                >
                                  <div className="flex justify-between">
                                    <p className="text-xs font-semibold text-gray-800 truncate">
                                      {result.name}
                                    </p>
                                    <p className="text-xs  text-gray-600 truncate">
                                      {result.status}
                                      <span className="ml-2 text-gray-300">
                                        status
                                      </span>{" "}
                                    </p>
                                  </div>
                                  <div className="flex justify-between">
                                    <p className="text-xs text-gray-600 truncate">
                                      {result.footprintUrl}
                                    </p>
                                    <p className="text-xs text-gray-600 truncate">
                           
                                      <TagsDisplay tags={result.tagsArray} />
                                    </p>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        {!isLoading &&
                          searchQuery &&
                          searchResults?.length === 0 && (
                            <p className="text-gray-600 text-sm py-1">
                              No data found
                            </p>
                          )}
                      </div>
                    </div>
                  )}
              </div> */}

              <div className="flex items-center gap-2">
                <FeedbackFish projectId="b5c3da700bc1a7" userId={user?.email}>
                  <button className="button bg-layout text-color-gray-light text-xs font-normal rounded-lg p-1.5 flex items-center">
                    <span>Share Feedback</span>
                  </button>
                </FeedbackFish>
                <div className="cicle-nick-name rounded-full bg-yellow text-[16px] px-3 py-1 font-normal">
                  {getInitials(user?.username)}
                </div>

                <span className="text-color-gray-light font-semibold text-lg">
                  {user?.username.split(/(?=[A-Z])/)[0]}
                </span>
                {/* <div className="username-tag rounded-2xl border text-blue border-blue px-3 text-xs font-semibold py-1">contributor</div>
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.6666 1L5.99992 5.66667L1.33325 1" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg> */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
