import React, { useEffect, useState } from 'react'
import Guide1 from '../components/Guide/Guide1'
import ExtensionAlert from '../components/Card/ExtensionAlert';
import {  useNavigate } from 'react-router-dom';

import axios from 'axios';
import { baseUrl } from '../config';
import SettingButton from '../components/Button/SettingButton';

const AddWebsitePage = () => {
    const navigate = useNavigate()
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);

    const [ErrorMessage, setErrorMessage] = useState("")
    const [isValied, setIsValied] = useState(false)
    const [activeFail, setActiveFail] = useState(false);
    const [activeSuccess, setActiveSuccess] = useState(false);
    const [value, setValue] = useState("");
    const [loading , setLoading] = useState(false)

    const [formData, setFormData] = useState({
        url: "",

    });

    const validateInput = (value) => {
        // Updated pattern to validate full URLs
        const urlPattern =
            /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
        const existingDomains = ["google.com", "example.com"]; // Add the list of domains already in use here

        try {
            const url = new URL(value); // Parse the input as a URL to extract the hostname

            if (existingDomains.includes(url.hostname)) {
                setErrorMessage(
                    "That website is already part of your or someone else’s account."
                );
                setIsValied(false)

            } else if (!urlPattern.test(value)) {
                setErrorMessage("That doesn’t look like a website URL.");
                setIsValied(false)
            } else {
                setErrorMessage("");
                setIsValied(true)
            }
        } catch (error) {
            // If URL parsing fails, the input is not a valid URL
            setErrorMessage("That doesn’t look like a website URL.");
            setIsValied(false)
        }
    };


    const addWebsite = async () => {
        setLoading(true)
        if (!formData.url) {
            setActiveFail(true)
            setValue("Enter the website Url")
            setLoading(false)
            return;
        }

        console.log("Input value:", formData.url);
        const token = localStorage.getItem("Token");
        console.log("token data is done", token, "------------------------");


        if (token) {
            try {
                const response = await axios.post(
                    `${baseUrl}/websites/`,
                    {
                        url: formData.url,


                    },
                    {
                        headers: { authorization: `Bearer ${token}` },
                    }
                );
                console.log("response0-----------------", response.data);
                // Store the latest added website ID in localStorage
                localStorage.setItem("selectedWebsiteId", response.data?.website?._id);
                setLoading(false)
                navigate(`/${response.data?.website?._id}/dashboard`);
            } catch (error) {
                console.log("error", error.response.data.msg)
                setErrorMessage(error.response.data.msg);
                setLoading(false)



            }
        }


    };

    


    const handleWebsiteChange = (e) => {
        validateInput(e.target.value)
        let value = e.target.value.trim();
        value = value
          .replace(/^https?:\/\/(www\.)?|^www\./, "")
          .replace(/\/+$/, "");
        const { name } = e.target;
        setFormData({ ...formData, [name]: value });
        
    };

    return (
        // <div className="setup-guide mt-5">
        //         <div className="inner-setup-guide text-color-gray-light">
        //         <Guide1 /> 
        //         </div>
        //       </div>
        <>
            {/* {!isExtensionInstalled && <ExtensionAlert />}

            <div className="container flex justify-center  m-auto h-[50vh] w-[60vw]  ">

                <div
                    className={`space-y-4 mx-4 py-2 flex flex-col gap-2 ${!isExtensionInstalled ? 'non-clickable' : ''
                        }`}
                    style={{
                        pointerEvents: isExtensionInstalled ? 'auto' : 'none',
                        opacity: isExtensionInstalled ? 1 : 0.5, // Optional: visually indicate non-clickability
                    }}
                >
                    <Guide1 />
                </div>

            </div> */}



            {/* const Website = ({handleWebsiteChange ,ErrorMessage ,formData}) => { */}









            <div class="flex   justify-center  mx-24 ">
                <div class="  w-[100vw]   h-[100vh] rounded-lg flex flex-col bg-white   border border-layout-border shadow-[0px_1px_2px_0px_#0000000D]  py-6 px-10">

                    <div class="flex-grow">


                        <div class=" text-color-gray-light font-semibold text-3xl">
                            Add your Website
                        </div>

                        <p class="text-sm font-medium text-color-gray-light pt-1.5">

                            To create tests for your website, you need to add your website in your account.
                        </p>
                        <div className="flex  space-x-6">

                            <div class=" mt-6 w-full " >

                                <div>
                                    <label for="email" class="block mb-2 text-base font-semibold text-auth-label ">Website URL</label>
                                    <input type="text" name="url" id="url" value={formData.urla} onChange={handleWebsiteChange} class="bg-white border text-color-gray-light sm:text-sm rounded-lg focus:outline-none   block w-full p-3 " placeholder="https://www.example.com" />
                                    {ErrorMessage && (
                                        <p className="text-error-msg font-medium text-sm pt-2">
                                            {ErrorMessage}
                                        </p>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="flex gap-4 justify-end p-2 mt-auto">

                        {/* <button
                            className={`px-3 py-2 min-w-[80px] text-center text-base text-bold border rounded-lg focus:outline-none focus:ring ${isValied
                                ? "text-white bg-button-bg hover:bg-button-hover active:text-white"
                                : "text-color-gray-light-400 bg-gray-300 cursor-not-allowed"
                                }`}
                            onClick={(e) => {
                                addWebsite()
                            }}
                            disabled={!isValied}
                        >
                            Done
                        </button> */}
                         <SettingButton text={"Save"} loading={loading} onClick={(e)=>addWebsite(e)} disabled={!isValied}/>


                    </div>
                </div>
            </div>




        </>



    )
}

export default AddWebsitePage