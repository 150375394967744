import React from 'react'
import Test_Landing from '../components/Footprint/Test_Landing'
import { useLocation } from 'react-router-dom';

const TestLandingPage = () => {
 
  return (
    <>
    <Test_Landing />
    </>
  )
}

export default TestLandingPage