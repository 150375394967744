import React, { useState, useEffect } from "react";
import { Table, Pagination, Checkbox } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import axios from "axios";
import SuccessToast from "../Toast/SuccessToast";
import { Dropdown, Menu, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../config";
import FailToast from "../Toast/FailToast";
import Alert_Card from "../Card/Alert_Card";





const CustomPagination = ({ current, pageSize, total, onChange }) => {
  const totalPages = Math.ceil(total / pageSize);

  return (
    <div className="flex items-center justify-between p-4">
      <div className="flex items-center justify-between w-full">
        {/* Previous Button */}
        <div
          className={`flex items-center text-color-gray-light text-xl font-semibold space-x-2 py-1 cursor-pointer ${current === 1 ? "opacity-50 pointer-events-none" : ""
            }`}
          onClick={() => current > 1 && onChange(current - 1)}
        >
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.92259 1.5886C7.24803 1.26317 7.24803 0.73553 6.92259 0.410093C6.59715 0.0846564 6.06951 0.0846564 5.74408 0.410093L0.744078 5.41009C0.418641 5.73553 0.418641 6.26317 0.744078 6.5886L5.74408 11.5886C6.06951 11.914 6.59715 11.914 6.92259 11.5886C7.24803 11.2632 7.24803 10.7355 6.92259 10.4101L3.34518 6.83268L14.6667 6.83268C15.1269 6.83268 15.5 6.45959 15.5 5.99935C15.5 5.53911 15.1269 5.16602 14.6667 5.16602L3.34518 5.16602L6.92259 1.5886Z"
              fill="#111322"
            />
          </svg>
          <span>Previous</span>
        </div>

        {/* Pagination Numbers */}
        <div className="flex items-center space-x-2">
          <Pagination
            current={current}
            pageSize={pageSize}
            total={total}
            onChange={onChange}
            hideOnSinglePage
            showSizeChanger={false}
            // prevIcon={false}
            // nextIcon={false}
            className="custom-pagination"

            itemRender={(page, type, originalElement) => {
              if (type === "page") {
                return (
                  <div
                    className={`w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${current === page
                      ? "bg-primary-button text-white"
                      : "bg-white border border-layout-border font-medium text-[16px] text-footer"
                      }`}
                  >
                    {page}
                  </div>
                );
              }
              // return null;
              return originalElement;
            }}
          />
        </div>

        {/* Next Button */}
        <div
          className={`flex items-center space-x-2 text-color-gray-light text-xl font-semibold py-1 cursor-pointer ${current === totalPages ? "opacity-50 pointer-events-none" : ""
            }`}
          onClick={() => current < totalPages && onChange(current + 1)}
        >
          <span>Next</span>
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.2559 0.410093C9.93049 0.0846564 9.40285 0.0846564 9.07741 0.410093C8.75197 0.73553 8.75197 1.26317 9.07741 1.5886L12.6548 5.16602H1.33333C0.873096 5.16602 0.5 5.53911 0.5 5.99935C0.5 6.45959 0.873096 6.83268 1.33333 6.83268H12.6548L9.07741 10.4101C8.75197 10.7355 8.75197 11.2632 9.07741 11.5886C9.40285 11.914 9.93049 11.914 10.2559 11.5886L15.2559 6.5886C15.5814 6.26317 15.5814 5.73553 15.2559 5.41009L10.2559 0.410093Z"
              fill="#111322"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};


const Test_List_Table = ({
  selectedTags,
  selectedStatus,
  websiteId,
  searchBar,
}) => {
  const navigate = useNavigate();
  const [activeFail, setActiveFail] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [value, setValue] = useState("");

  const [showPop, setShowPopup] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handlePopup = (record) => {
    setSelectedRecord(record);

    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setSelectedRecord(null);
    setShowPopup(false);
  };



  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [dataSource, setDataSource] = useState([]);

  const [filters, setFilters] = useState({
    tags: selectedTags,
    status: selectedStatus,
    search: searchBar,
  });




  console.log("jai", filters)
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay)
    }
  }


  // Update filters with debounce for searchBar
  useEffect(() => {
    const updateFilters = debounce((searchValue) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: searchValue,
      }));
    }, 300); // Adjust debounce delay (300ms is common)

    updateFilters(searchBar);
    return () => clearTimeout(updateFilters); // Cleanup on unmount
  }, [searchBar]);



  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, current: page }));
  };

  const handlePageSizeChange = (size) => {
    setPagination((prev) => ({ ...prev, pageSize: size, current: 1 }));
  };

  const pauseSchedule = async (record) => {
    try {
      const response = await axios.post(`${baseUrl}/schedule/pause`, {
        footprintId: record.key,
      });
      console.log("API response for pauseSchedule:", response.data);
      setActiveSuccess(true);
      setValue(response.data.msg);
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };




  const CustomDropdownIcon = ({ color = "#1570EF" }) => (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6668 1L6.00016 5.66667L1.3335 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );



  const resumeSchedule = async (record) => {
    const token = localStorage.getItem("Token");
    if (token) {
      try {
        // const response = await axios.post(`${baseUrl}/schedule/resume`, {
        //   footprintId: record.key,
        // });
        const response = await axios.post(
          `${baseUrl}/schedule/resume`,
          { footprintId: record.key },
          {
            headers: { authorization: `Bearer ${token}` },
          }
        );
        console.log("API response for resumeSchedule:", response.data);
        setActiveSuccess(true);
        setValue(response.data.message);
      } catch (error) {
        console.error("Error making API call:", error);
      }
    }
  };
  const handleActionClick = async (record) => {
    if (record.status === "active") {
      console.log("record for stop button", record);
      pauseSchedule(record);
    }
    if (record.status === "stopped") {
      console.log("record for stop button", record);
      resumeSchedule(record);
    }
  };
  const handleRestart = (record) => {
    console.log("Row clicked for normal columns:", record);
    const data = { footprintId: record.key, websiteId: websiteId };
    navigate(`/Savefootprint/${record.key}/website/${websiteId}`, { state: { isRestart: true } });
  };


  const handleRowClick = (record) => {
    console.log("Row clicked for normal columns:", record);
    const data = { footprintId: record.key, websiteId: websiteId, footprintName: record.fpName, footprintNumber: record.footprint, recordData: record };
    record.status === "inactive" ? navigate(`/Savefootprint/${record.key}/website/${websiteId}`, { state: { Back: false } }) : navigate(`/${websiteId}/${record.key}/test-info`, { state: data });

    // navigate(`/footprint-list`, { state: data });
  };
  console.log("check", filters)



  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 3000);
    }
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
        const data = { websiteId: websiteId };
        // navigate("/footprint-status-list", { state: data });
        navigate(`/${websiteId}/test-list`);
        window.location.reload()
      }, 2000);
    }
  }, [activeFail, activeSuccess]);



  const [sortOrder, setSortOrder] = useState("ascend"); // Track the sort order

  const handleSort = (dataIndex) => {
    const newSortOrder = sortOrder === "ascend" ? "descend" : "ascend";

    // Sort the data
    const sortedData = [...dataSource].sort((a, b) => {
      if (newSortOrder === "ascend") {
        return a[dataIndex].localeCompare(b[dataIndex]);
      } else {
        return b[dataIndex].localeCompare(a[dataIndex]);
      }
    });

    // // Add the dynamic footprint
    // const sortedWithFootprint = sortedData.map((item, index) => ({
    //   ...item,
    //   footprint: `TT #${index + 1}`, // Dynamically assign footprint based on the sorted index
    // }));

    // Update state
    setSortOrder(newSortOrder);
    // setDataSource(sortedWithFootprint);
    setDataSource(sortedData);


  };




  const toggleMonitor = async (record) => {
    try {
      const footprintId = record.key;
      const action = record.isMonitored ? "unmark" : "mark"; // Determine action
      const response = await axios.post(`${baseUrl}/footprint/${action}`, {
        footprintId,
        websiteId,
      });

      if (response.status === 200) {
        // Update the dataSource locally
        setDataSource((prevData) =>
          prevData.map((item) =>
            item.key === record.key
              ? { ...item, isMonitored: !item.isMonitored }
              : item
          )
        );

        setActiveSuccess(true);
        setValue(response.data.message);
      }
    } catch (error) {
      console.error("Error toggling monitor status:", error);
      alert(
        error.response?.data?.error ||
        "An error occurred while updating the monitor status."
      );
    }
  };


  const deleteFootprint = async (record) => {
    if (record) {
      try {
        const response = await axios.delete(
          `${baseUrl}/footprint/${record?.key}`
        );
        console.log("response from footprint by id", response.data);

        window.location.reload();

      } catch (error) {
        console.log("Error in fetching data", error.response);
      }
    }
  };

  // Define columns with the new headers and checkbox
  const columns = [
    {
      title: <div className="header-section flex items-center gap-3 ">

        <span className="text-input-remark">Test ID
        </span>
        <svg className="mt-0.5" width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg"
        >


          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9999 0.599609C4.22082 0.599609 4.3999 0.778697 4.3999 0.999609V8.03393L6.91702 5.51677C7.07326 5.36055 7.32654 5.36055 7.48278 5.51677C7.63894 5.67298 7.63894 5.92624 7.48278 6.08245L4.28274 9.28249C4.20774 9.35745 4.10599 9.39961 3.9999 9.39961C3.89382 9.39961 3.79207 9.35745 3.71706 9.28249L0.517064 6.08245C0.360848 5.92624 0.360848 5.67298 0.517064 5.51677C0.673272 5.36055 0.926536 5.36055 1.08274 5.51677L3.5999 8.03393V0.999609C3.5999 0.778697 3.77899 0.599609 3.9999 0.599609Z" fill="#5D6B98" />
        </svg>
      </div>,
      dataIndex: "footprint",
      key: "footprint",
      render: (_, record) => (
        <div className="flex items-center">
          {/* <Checkbox
            checked={record.selected}
            onChange={(e) => handleCheckboxChange(record.key, e.target.checked)}
            className="mr-2"
          /> */}
          <span className="font-bold text-color-gray-light">
            {record.footprint}
          </span>
        </div>
      ),
    },


    {
      title: (
        <div className="header-section flex items-center gap-3">
          <span className="text-input-remark">Test Name</span>
          <svg
            className="mt-0.5 cursor-pointer"
            width="8"
            height="10"
            viewBox="0 0 8 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => handleSort("fpName")} // Trigger custom sort
            style={{
              transform: sortOrder === "ascend" ? "rotate(0deg)" : "rotate(180deg)", // Rotate based on sortOrder
              transition: "transform 0.3s ease", // Smooth transition for rotation
            }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.9999 0.599609C4.22082 0.599609 4.3999 0.778697 4.3999 0.999609V8.03393L6.91702 5.51677C7.07326 5.36055 7.32654 5.36055 7.48278 5.51677C7.63894 5.67298 7.63894 5.92624 7.48278 6.08245L4.28274 9.28249C4.20774 9.35745 4.10599 9.39961 3.9999 9.39961C3.89382 9.39961 3.79207 9.35745 3.71706 9.28249L0.517064 6.08245C0.360848 5.92624 0.360848 5.67298 0.517064 5.51677C0.673272 5.36055 0.926536 5.36055 1.08274 5.51677L3.5999 8.03393V0.999609C3.5999 0.778697 3.77899 0.599609 3.9999 0.599609Z"
              fill="#5D6B98"
            />
          </svg>
        </div>
      ),
      dataIndex: "fpName",
      key: "fpName",
      render: (text) => <span className="text-sm text-gray-700">{text}</span>,
    },

    {
      title:

        <div className="header-section flex items-center gap-3 ">

          <span className="text-input-remark">Creation Date & Time
          </span>
          <svg className="mt-0.5" width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9999 0.599609C4.22082 0.599609 4.3999 0.778697 4.3999 0.999609V8.03393L6.91702 5.51677C7.07326 5.36055 7.32654 5.36055 7.48278 5.51677C7.63894 5.67298 7.63894 5.92624 7.48278 6.08245L4.28274 9.28249C4.20774 9.35745 4.10599 9.39961 3.9999 9.39961C3.89382 9.39961 3.79207 9.35745 3.71706 9.28249L0.517064 6.08245C0.360848 5.92624 0.360848 5.67298 0.517064 5.51677C0.673272 5.36055 0.926536 5.36055 1.08274 5.51677L3.5999 8.03393V0.999609C3.5999 0.778697 3.77899 0.599609 3.9999 0.599609Z" fill="#5D6B98" />
          </svg>
        </div>,
      dataIndex: "createdDateTime",
      key: "createdDateTime",
      render: (text) => (
        <div className="text-sm text-black">
          <div>{text.date}</div>
          {/* <div className="text-xs text-gray-500">{text.dayName}</div> */}
        </div>
      ),
    },
    {
      title:
        <div className="header-section flex items-center gap-3 ">

          <span className="text-input-remark">Last Run Date & Time
          </span>
          <svg className="mt-0.5" width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9999 0.599609C4.22082 0.599609 4.3999 0.778697 4.3999 0.999609V8.03393L6.91702 5.51677C7.07326 5.36055 7.32654 5.36055 7.48278 5.51677C7.63894 5.67298 7.63894 5.92624 7.48278 6.08245L4.28274 9.28249C4.20774 9.35745 4.10599 9.39961 3.9999 9.39961C3.89382 9.39961 3.79207 9.35745 3.71706 9.28249L0.517064 6.08245C0.360848 5.92624 0.360848 5.67298 0.517064 5.51677C0.673272 5.36055 0.926536 5.36055 1.08274 5.51677L3.5999 8.03393V0.999609C3.5999 0.778697 3.77899 0.599609 3.9999 0.599609Z" fill="#5D6B98" />
          </svg>
        </div>,
      dataIndex: "lastRun",
      key: "lastRun",
      render: (text) => (
        <div className="text-sm text-black">
          <div>{text.date}</div>
          {/* <div className="text-xs text-gray-500">{text.dayName}</div> */}
        </div>
      ),
    },



    {
      title: (
        <div className="header-section flex items-center gap-3 mx-2">

          <span className="text-input-remark">Status
          </span>
          <svg className="mt-0.5" width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9999 0.599609C4.22082 0.599609 4.3999 0.778697 4.3999 0.999609V8.03393L6.91702 5.51677C7.07326 5.36055 7.32654 5.36055 7.48278 5.51677C7.63894 5.67298 7.63894 5.92624 7.48278 6.08245L4.28274 9.28249C4.20774 9.35745 4.10599 9.39961 3.9999 9.39961C3.89382 9.39961 3.79207 9.35745 3.71706 9.28249L0.517064 6.08245C0.360848 5.92624 0.360848 5.67298 0.517064 5.51677C0.673272 5.36055 0.926536 5.36055 1.08274 5.51677L3.5999 8.03393V0.999609C3.5999 0.778697 3.77899 0.599609 3.9999 0.599609Z" fill="#5D6B98" />
          </svg>
        </div>
      ),
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div className="flex items-center" onClick={(e) => {
          e.stopPropagation(); // Prevent row click event
        }}>
          {/* Capsule with dropdown inside */}
          <div
            className={`flex items-center justify-between px-3 gap-1 py-1 rounded-[99px] text-xs font-semibold w-max ${text === "active"
              ? "bg-blue text-blue border border-blue"
              : text === "complete"
                ? "bg-green text-green border border-green "
                : "bg-layout text-dark-gray border border-status-border"
              }`}
          >
            {/* Status Text */}
            <span className="">
              {text === "active"
                ? "Active"
                : text === "inactive"
                  ? "Draft"
                  : text === "stopped"
                    ? "Paused"
                    : "Completed"}
            </span>



            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: `${record.fpName}`,
                    style: {
                      backgroundColor: "#EFF1F5",
                      color: "#4A5578",
                      fontSize: "0.875rem",
                      fontWeight: "600",
                      borderRadius: "8px 8px 0 0",
                      width: "182px",
                      marginLeft: "-4px",
                      marginRight: "-4px",
                      marginTop: "-5px",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      paddingLeft: "12px",
                      paddingRight: "12px",


                    },
                  },
                  ...(record?.status === "active"
                    ? [
                      {
                        key: "2",
                        label: "Pause",
                        onClick: () => handleActionClick(record),
                        style: {
                          color: "var(--color-gray-light)",
                          fontSize: "0.875rem",
                          fontWeight: "600",
                          padding: "12px", // Add padding
                          marginLeft: "-4px",
                          marginRight: "-4px",
                          // borderRadius: "0px"

                          marginBottom: "-4px",

                            // borderRadius: "0px"

                            borderBottomRightRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            borderTopLeftRadius: "0px",
                            borderTopRightRadius: "0px"

                        },
                      },
                    ]
                    : record?.status === "stopped"
                      ? [
                        {
                          key: "3",
                          label: "Run",
                          onClick: () => handleActionClick(record),
                          style: {
                            color: "var(--color-gray-light)",
                            fontSize: "0.875rem",
                            fontWeight: "600",
                            padding: "12px", // Add padding
                            marginLeft: "-4px",
                            marginRight: "-4px",
                            marginBottom: "-4px",

                            // borderRadius: "0px"

                            borderBottomRightRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            borderTopLeftRadius: "0px",
                            borderTopRightRadius: "0px"

                          },
                        },
                      ]
                      : record?.status === "complete"
                        ? [
                          {
                            key: "4",
                            label: "Restart",
                            onClick: () => handleRestart(record),
                            style: {
                              color: "var(--color-gray-light)",
                              fontSize: "0.875rem",
                              fontWeight: "600",
                              padding: "12px", // Add padding
                              marginLeft: "-4px",
                              marginRight: "-4px",
                              // borderRadius: "0px"
                              marginBottom: "-4px",

                            // borderRadius: "0px"

                            borderBottomRightRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            borderTopLeftRadius: "0px",
                            borderTopRightRadius: "0px"

                            },
                          },
                        ]
                        : record?.status === "inactive"
                          ? [
                            {
                              key: "5",
                              label: "Schedule",
                              onClick: () => handleRowClick(record),
                              style: {
                                color: "var(--color-gray-light)",
                                fontSize: "0.875rem",
                                fontWeight: "600",
                                padding: "12px", // Add padding
                                marginLeft: "-4px",
                                marginRight: "-4px",
                                // borderRadius: "0px"
                                marginBottom: "-4px",

                            // borderRadius: "0px"

                            borderBottomRightRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            borderTopLeftRadius: "0px",
                            borderTopRightRadius: "0px"

                              },
                            },
                          ]
                          : []),
                  // {
                  //   key: "6",
                  //   label: "Delete",
                  //   // onClick: () => deleteFootprint(record),
                  //   onClick: () => handlePopup(record),
                  //   style: {
                  //     color: "var(--color-gray-light)",
                  //     fontSize: "0.875rem",
                  //     fontWeight: "600",
                  //     padding: "12px", // Add padding
                  //     marginLeft: "-4px",
                  //     marginRight: "-4px",
                  //     marginBottom: "-4px",
                  //     borderBottomRightRadius: "8px",
                  //     borderBottomLeftRadius: "8px",
                  //     borderTopLeftRadius: "0px",
                  //     borderTopRightRadius: "0px"
                  //   },
                  // },
                ],
              }}
              trigger={["click"]}
            >
              <Button
                type="text"
                size="small"
                icon={
                  <CustomDropdownIcon
                    color={text === "active" ? "#1570EF" : text === "complete" ? "#039855" : "#4A5578"}
                  />
                }
                className="p-0"
              />
            </Dropdown>

          </div>
        </div>
      ),
    },



    // {
    //   title: <span className="text-input-remark"></span>,
    //   dataIndex: "visualize",
    //   key: "visualize",
    //   render: (text, record) => {
    //     console.log(record, "visualize");
    //     return (
    //       <div className="flex items-center cursor-pointer" onClick={(e) => {
    //         e.stopPropagation();
    //         toggleMonitor(record)
    //       }
    //       }  >
    //         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={record.isMonitored ? "#000000" : "#7D89B0"} viewBox="0 0 256 256"><path d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0V156.69l50.34-50.35a8,8,0,0,1,11.32,0L128,132.69,180.69,80H160a8,8,0,0,1,0-16h40a8,8,0,0,1,8,8v40a8,8,0,0,1-16,0V91.31l-58.34,58.35a8,8,0,0,1-11.32,0L96,123.31l-56,56V200H224A8,8,0,0,1,232,208Z"></path></svg>
    //       </div>
    //     )
    //   },
    // },

    {
      title: "",
      dataIndex: "additional",
      key: "additional",
      render: (text, record) => (
        <div className="flex items-center" onClick={(e) => {
          e.stopPropagation(); // Prevent row click event
        }}>



          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: `${record.fpName}`,
                  style: {
                    backgroundColor: "#EFF1F5",
                    color: "#4A5578",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                    borderRadius: "8px 8px 0 0",
                    width: "182px",
                    marginLeft: "-4px",
                    marginRight: "-4px",
                    marginTop: "-5px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    paddingLeft: "12px",
                    paddingRight: "12px",


                  },
                },
                ...(record?.status === "complete1"
                  ? [
                    {
                      key: "2",
                      label: "Restart",
                      onClick: () => handleRestart(record),
                      style: {
                        color: "var(--color-gray-light)",
                        fontSize: "0.875rem",
                        fontWeight: "600",
                        padding: "12px", // Add padding
                        marginLeft: "-4px",
                        marginRight: "-4px",
                        borderRadius: "0px"

                      },
                    },
                  ]

                  : []),
                {
                  key: "3",
                  label:  `${record?.isMonitored===true ? "Remove from monitor" :"Monitor on dashboard"}`,
                  // onClick: () => deleteFootprint(record),
                  onClick: (e) => {
                    toggleMonitor(record)
                  },

                  style: {
                    color: "var(--color-gray-light)",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                    padding: "12px", // Add padding
                    marginLeft: "-4px",
                    marginRight: "-4px",
                    marginBottom: "-4px",
                    borderRadius: "0px"

                  },
                },
                {
                  key: "4",
                  label: "Delete Test",
                  // onClick: () => deleteFootprint(record),
                  onClick: () => handlePopup(record),
                  style: {
                    color: "#F04438",
                    fontSize: "0.875rem",
                    fontWeight: "600",
                    padding: "12px", // Add padding
                    marginLeft: "-4px",
                    marginRight: "-4px",
                    marginBottom: "-4px",
                    borderBottomRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px"
                  },
                },
              ],
            }}
            trigger={["click"]}
          >
            <Button
              type="text"
              size="small"
              icon={
                <svg width="4" height="12" viewBox="0 0 4 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.3335 9.99967C1.3335 10.3679 1.63197 10.6663 2.00016 10.6663C2.36835 10.6663 2.66683 10.3679 2.66683 9.99967C2.66683 9.63148 2.36835 9.33301 2.00016 9.33301C1.63197 9.33301 1.3335 9.63148 1.3335 9.99967Z" fill="#111322" />
                  <path d="M1.3335 5.99967C1.3335 6.36786 1.63197 6.66634 2.00016 6.66634C2.36835 6.66634 2.66683 6.36786 2.66683 5.99967C2.66683 5.63148 2.36835 5.33301 2.00016 5.33301C1.63197 5.33301 1.3335 5.63148 1.3335 5.99967Z" fill="#111322" />
                  <path d="M1.3335 1.99967C1.3335 2.36786 1.63197 2.66634 2.00016 2.66634C2.36835 2.66634 2.66683 2.36786 2.66683 1.99967C2.66683 1.63148 2.36835 1.33301 2.00016 1.33301C1.63197 1.33301 1.3335 1.63148 1.3335 1.99967Z" fill="#111322" />
                  <path d="M1.3335 9.99967C1.3335 10.3679 1.63197 10.6663 2.00016 10.6663C2.36835 10.6663 2.66683 10.3679 2.66683 9.99967C2.66683 9.63148 2.36835 9.33301 2.00016 9.33301C1.63197 9.33301 1.3335 9.63148 1.3335 9.99967Z" stroke="#0E101B" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M1.3335 5.99967C1.3335 6.36786 1.63197 6.66634 2.00016 6.66634C2.36835 6.66634 2.66683 6.36786 2.66683 5.99967C2.66683 5.63148 2.36835 5.33301 2.00016 5.33301C1.63197 5.33301 1.3335 5.63148 1.3335 5.99967Z" stroke="#0E101B" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M1.3335 1.99967C1.3335 2.36786 1.63197 2.66634 2.00016 2.66634C2.36835 2.66634 2.66683 2.36786 2.66683 1.99967C2.66683 1.63148 2.36835 1.33301 2.00016 1.33301C1.63197 1.33301 1.3335 1.63148 1.3335 1.99967Z" stroke="#0E101B" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

              }
              className="p-0"
            />
          </Dropdown>

        </div>
      ),
    },

  ];



  const getAllFootprintsData = async () => {
    try {
      // Construct query parameters based on tags and status
      let queryParams = "";
      if (filters.tags.length > 0 || filters.status || filters.search) {
        console.log("mai enter hua ", filters.status);
        const tagsParam =
          filters.tags.length > 0 ? `tags=${filters.tags.join(",")}` : "";
        const statusParam = filters.status ? `status=${filters.status}` : "";
        const searchParam = filters.search ? `search=${filters.search} ` : "";


        queryParams = `?${[tagsParam, statusParam, searchParam].filter(Boolean).join("&")}`;
      }

      // Make the API call with query parameters if present
      const response = await axios.get(
        `${baseUrl}/footprint/${websiteId}${queryParams}`
      );

      console.log("DATA", response.data.length, queryParams);
      console.log("Filters", filters);

      // Set the filtered data in the state
      // setDataSource(formatData(response.data));
      setDataSource(formatData(response.data));
      // Reset pagination to the first page
      setPagination((prev) => ({ ...prev, current: 1 }));

    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    // Update filters by preserving the previous state and updating the tags
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: selectedStatus,
    }));
  }, [selectedStatus]);



  const formatDateTime = (isoString) => {
    try {
      const date = new Date(isoString);

      if (isNaN(date.getTime())) {
        throw new Error(`Invalid ISO string: ${isoString}`);
      }

      // Correct month option
      const options = { month: "short", day: "numeric" }; // Use "short", "long", "numeric", or "2-digit" for `month`
      const formattedDate = date.toLocaleDateString("en-US", options);

      const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };
      const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

      const dayName = date.toLocaleDateString("en-US", { weekday: "long" });

      return {
        date: `${formattedDate}, at ${formattedTime}`,
        dayName,
      };
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return {
        date: "Invalid date",
        dayName: "Invalid day",
      };
    }
  };

  const formatData = (data) => {
    return data.map((item, index) => {
      const createdDateTime = formatDateTime(item.createdAt);
      const lastRunDateTime = item.lastRunAt
        ? formatDateTime(item.lastRunAt)
        : { date: "N/A", dayName: "" };

      const totalRuns = item.successCount + item.failureCount;

      const successPercentage = Math.floor((item.successCount / totalRuns) * 100);
      const failurePercentage = Math.floor((item.failureCount / totalRuns) * 100)



      return {
        key: item._id,
        footprint: `TT #${index + 1}`,
        fpName: item.name,
        createdDateTime: createdDateTime,
        lastRun: lastRunDateTime,
        status: item.status,
        successfulRuns:
          successPercentage !== undefined && !isNaN(successPercentage)
            ? `${successPercentage}%`
            : "--",
        failedRuns:
          failurePercentage !== undefined && !isNaN(failurePercentage)
            ? `${failurePercentage}%`
            : "--",
        selected: false,
        isMonitored: item.isMonitored
      };
    });
  };





  useEffect(() => {
    getAllFootprintsData();
    console.log("log-1", filters)
  }, [filters, websiteId]);






  return (
    <div className="">
      {activeSuccess && (
        <SuccessToast
          successValue={value}
          activeSuccess={activeSuccess}
          setActiveSuccess={setActiveSuccess}
        />
      )}
      {activeFail && (
        <FailToast
          errorValue={value}
          activeFail={activeFail}
          setActiveFail={setActiveFail}
        />
      )}

      {showPop && (
        <>
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>{" "}
          {/* Overlay */}
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <Alert_Card onClose={handleClosePopup} type="delete" mainContent={
              <>
                Are you sure you want to delete <br />
                this test?
              </>
            }

              smallContent="Once you delete a test, we will no longer run it" handleDelete={() => deleteFootprint(selectedRecord)}



            />
          </div>
        </>
      )}

      <Table
        columns={columns}
        // dataSource={dataSource}
        dataSource={dataSource.slice(
          (pagination.current - 1) * pagination.pageSize,
          pagination.current * pagination.pageSize
        )}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              // console.log("Row clicked:", record, rowIndex);
              // const data = { footprintId: record.key, websiteId: websiteId };
              // navigate(`/footprint-list`, { state: data });
              // Perform any action here, like navigating to another page or opening a modal
              handleRowClick(record, rowIndex);
            },
          };
        }}
        pagination={false}
        // rowClassName={(record, index) => (index % 2 === 0 ? 'bg-[#FBE5C9]' : '')}
        bordered={false} // Ensure `bordered` is set to false
        className="custom-table"


        components={{
          header: {
            cell: (props) => (
              <th
                {...props}
                className="!bg-layout text-input-remark !font-semibold text-xs"
              />
            ),
          },
        }}
      />

      {
        dataSource?.length > 10 && (<CustomPagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={dataSource.length}
          onChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}

        />)
      }
    </div>
  );
};

export default Test_List_Table;
