import React, { useState } from "react";

const Skill = ({ formData, setFormData }) => {
  const [selectedRole, setSelectedRole] = useState("")
  const [selectedSkill, setSelectedSkill] = useState("")
  // const handleChange = (event, id) => {
  //   setSkillToggleStates((prevState) => ({
  //     ...prevState,
  //     [id]: event.target.checked,
  //   }));
  // };
  const Roles = ["Cxo", "Product", "Quality", "Engineering", "Others"]
  const Skills = ["Identify Bugs", "QA Automation", "Sanity Testing", "Design", "Others"]


  const handleRoleChange = (event) => {
    const newRole = event.target.value
    setSelectedRole(newRole); // Update the selectedCountry state
    setFormData((prevData) => ({
      ...prevData,
      role: newRole, // Update the country field in formData
    }));
  };


  const handleSkillChange = (event) => {
    const newSkill = event.target.value;
    setSelectedSkill(newSkill); // Update the selectedCountry state
    setFormData((prevData) => ({
      ...prevData,
      skill: newSkill, // Update the country field in formData
    }));
  };

  // "Identify bugs": false,
  // "QA Automation": false,
  // "Sanity Testing": false,
  // Design: false,
  // Others_skill: false,

  // CXO: false,
  // Product: false,
  // Quality: false,
  // Engineering: false,
  // Others_role: false,

  return (

    <div className="flex  space-x-6">

      <div class=" mt-6 w-full space-y-6 " >

        {/* <div>
          <label
            htmlFor="country"
            className="block mb-2 text-base font-semibold text-color-gray-light"
          >
            What’s your role
          </label>
          <select
            id="country"
            name="country"
            className="bg-gray-50 border max-h-20 border-gray-300 text-color-gray-light sm:text-sm rounded-lg  block w-full p-3 pr-8 shadow-sm "

          >
            <option value="">
              Select Country
            </option>

          </select>



        </div> */}

        <div>
          <label
            htmlFor="country"
            className="block mb-2 text-base font-semibold text-color-gray-light"
          >
            What’s your role
          </label>
          <div className="relative">
            <select
              id="role"
              name="role"
              value={formData.role }
              onChange={handleRoleChange}
              className="bg-white border max-h-20 focus:outline-none border-gray-300 text-color-gray-light sm:text-sm rounded-lg block w-full p-3 pr-12 shadow-sm appearance-none"
            >
              <option value="">Select Role</option>
              {Roles.map((role, i) => (
                <option key={i} value={role}>
                  {role}
                </option>
              ))}
            </select>
            <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none text-gray-400">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"

              >

                <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="#616161" />
                <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
              </svg>

            </span>
          </div>
        </div>
        <div>
          <label
            htmlFor="country"
            className="block mb-2 text-base font-semibold text-color-gray-light"
          >
            What is your purpose of using TestTube?
          </label>
          <div className="relative">
            <select
              id="skill"
              name="skill"
              value={formData.skill}
              onChange={handleSkillChange}
              className="bg-white border max-h-20 focus:outline-none border-gray-300 text-color-gray-light sm:text-sm rounded-lg block w-full p-3 pr-12 shadow-sm appearance-none"
            >
              <option value="">Select Purpose</option>
              {Skills.map((skill, i) => (
                <option key={i} value={skill}>
                  {skill}
                </option>
              ))}
            </select>
            <span className="absolute inset-y-0 right-3 flex items-center pointer-events-none text-gray-400">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"

              >

                <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="#616161" />
                <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
              </svg>

            </span>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Skill;



{/* <div>
<label
    htmlFor="country"
    className="block mb-2 text-base font-semibold text-gray-700"
>
    Country
</label>
<select
    id="country"
    name="country"
    value={formData.country || selectedCountry}
    onChange={handleCountryChange}
    className="bg-gray-50 border max-h-20 border-gray-300 text-color-gray-light sm:text-sm rounded-lg  block w-full p-3 pr-8 shadow-sm "

>
    <option value="">
        Select Country
    </option>

    {countries.map((country) => (
        <option key={country.code} value={country.name}>
            {country.name}
        </option>
    ))}


</select>
{errors.country && (
    <p className="text-error-msg font-medium text-sm pt-2">
        {errors.country}
    </p>
)}


</div> */}