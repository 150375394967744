import React, { useEffect, useState } from 'react'
import Test_List_Table from '../Table/Test_List_Table';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../config';

const Test_All_List = () => {
    const { websiteId } = useParams();
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [filter, setFilter] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [data,setData]=useState();


    const handleInputChange = (e) => {
        setSearchText(e.target.value);
    };

    const getStatusOfAllFootprint=async ()=>{
        const response = await  axios.get(`${baseUrl}/websites/${websiteId}/count-status`);
        console.log("counting",response.data)
        setData(response.data);
        
    }

    useEffect(()=>{
     getStatusOfAllFootprint();
     
    },[filter])

    const handleFilterClick = (status) => {
        setFilter(status);
    };
    const handleSelectChange = (newSelection) => {
        setSelectedValues(newSelection);
    };

    const handleStatusChange = (newSelection) => {
        setSelectedStatus(newSelection);
    };
    return (
        <div className="container-2">
            <div className="wrapper w-full h-auto  min-h-[80vh] flex flex-col gap-6  rounded-xl">  {/** 70 vh to 90vh  , border border-layout-border*/}
                <div className="top-section">
                    <div className="inner-section flex justify-between ">
                        <div className="filter-section">
                            <div className="filter-items">
                                <div className="items flex justify-between border border-layout-border font-medium text-sm items-center gap-2.5  rounded-md p-[5px] text-filter-color">
                                    <div
                                        className={`item-1 rounded-[4px] px-3 py-1.5  cursor-pointer transition ease-out duration-300 ${filter==""  ? "bg-white shadow-[0px_1px_2px_0px_#0000000D] text-color-gray-light font-semibold " : ""
                                            }`}
                                        onClick={() => handleFilterClick("")}
                                    >
                                        All
                                    </div>
                                    <div
                                        className={`item-2 rounded-[4px] px-3 py-1.5 cursor-pointer ${filter === "active" ? "bg-white shadow-[0px_1px_2px_0px_#0000000D] text-color-gray-light transition ease-out duration-300" : ""
                                            }`}
                                        onClick={() => handleFilterClick("active")}
                                    >
                                        Active ({data?.totalActiveCount})
                                    </div>
                                    <div
                                        className={`item-3 rounded-[4px] px-3 py-1.5 cursor-pointer ${filter === "stopped" ? "bg-white shadow-[0px_1px_2px_0px_#0000000D] text-color-gray-light transition ease-out duration-300" : ""
                                            }`}
                                        onClick={() => handleFilterClick("stopped")}
                                    >
                                        Paused ({data?.totalPausedCount})
                                    </div>

                                    <div
                                        className={`item-4 rounded-[4px] px-3 py-1.5 cursor-pointer ${filter === "complete" ? "bg-white shadow-[0px_1px_2px_0px_#0000000D] text-color-gray-light transition ease-out duration-300" : ""
                                            }`}
                                        onClick={() => handleFilterClick("complete")}
                                    >
                                        Completed ({data?.totalCompleteCount})
                                    </div>
                                    <div
                                        className={`item-5 rounded-[4px] px-3 py-1.5 cursor-pointer ${filter === "inactive" ? "bg-white shadow-[0px_1px_2px_0px_#0000000D] text-color-gray-light transition ease-out duration-300" : ""
                                            }`}
                                        onClick={() => handleFilterClick("inactive")}
                                    >
                                        Draft ({data?.totalInactiveCount})
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="search-bar">
                            <div className="relative flex items-center py-[11px] px-6 border border-link-border rounded-full">
                                <input
                                    type="text"
                                    placeholder="Search test"
                                    value={searchText}
                                    onChange={handleInputChange}
                                    className="w-full  pr-10 font-normal text-color-gray-light bg-transparent border-none text-sm tracking-wider focus:outline-none  placeholder-disable-secondary"
                                />
                                <svg
                                    className="absolute right-5 top-1/2 transform -translate-y-1/2"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13 13L19 19M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15Z"
                                        stroke="#DCDFEA"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="list-section ">
                    <div className="table-list border border-layout-border shadow-[0px_1px_2px_0px_#0000000D]  rounded-lg">
                        <div className="flex-grow">
                            <Test_List_Table websiteId={websiteId}
                                selectedTags={selectedValues}
                                selectedStatus={filter}
                                searchBar={searchText}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Test_All_List