import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { baseUrl } from "../../config";

const FloatingInput = ({
  formData,
  setFormData,
  footPrint,
  isReadOnly,
  isEditable,
  isButtonEnabled = true
}) => {
  const [footprintNameError, setFootprintNameError] = useState("");
  const [enableError , setEnableError] = useState(false)
  const [tagsArray, setTagsArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  console.log("formData for edit", formData.tags, tagsArray);

  console.log("isButtonEnabled",isButtonEnabled , "enableError",enableError)
  

  const websiteId = formData.websiteId;
  // let Tags = footPrint?.tags;
  // let footprintTags = Tags?.join(", ");

  // console.log("WebsiteId passed in formInput label",formData.websiteId)


  const checkFootprintUnique = async (value) => {

    const response = await axios.post(`${baseUrl}/footprint/checkFootprint/${websiteId}`, { value });
    return response.data?.isUnique;

  }


  // Custom debounce function
  function debounce(func, delay) {
    let timer;
    return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }


  // Define the debounced function using useCallback, to ensure it is not re-created
  const debouncedCheckUnique = useCallback(
    debounce(async (value) => {
      const isUnique = await checkFootprintUnique(value);
      if (!isUnique) {
        setFootprintNameError("Footprint name must be unique!");
      }
    }, 500),
    [] // Empty array ensures it is created once
  );

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    setErrorMessage("");


    if (name === "footprintName") {
      // Reset error message first
      setFootprintNameError("");
      // Debounce the API call to check uniqueness
      debouncedCheckUnique(value);
    }

    // if (name === "footprintUrl") {
    //     setEnableError(true)
      
    // }

  };

  const handleKeyDown = (event) => {
    const { value } = event.target;

    if (event.key === "Enter" && value.trim() !== "") {
      event.preventDefault();
      // Add tag if "Enter" or "Space" is pressed and value is not empty

      if (!tagsArray.includes(value.trim())) {

        setTagsArray([...tagsArray, value.trim()]);

        // Add tag to the tagsArray in formData
        setFormData((prevFormData) => ({
          ...prevFormData,
          tagsArray: [...prevFormData.tagsArray, value.trim()], // Add new tag
          tags: "", // Clear the input field
        }));
        setErrorMessage("");

      }
      else {
        setErrorMessage("Tags Must be unique !");
      }
    }
  };

  const removeTag = (index) => {
    const updatedTags = tagsArray.filter((_, i) => i !== index);
    setTagsArray(updatedTags);

    setFormData((prevFormData) => {
      const updatedTags = prevFormData.tagsArray.filter((_, i) => i !== index);
      return {
        ...prevFormData,
        tagsArray: updatedTags,
      };
    });
  };

  return (
    <div className="space-y-4 mx-4 py-2 flex flex-col gap-2 ">
      <div className="relative w-full text-[16px] ">
        <input
          type="text"
          id="floatingInput1"
          name="footprintName"
          value={formData.footprintName}
          placeholder=" " // Keep the placeholder to trigger the peer classes
          disabled={!isEditable}
          onChange={handleChange}
          className="block w-full px-2.5 pb-2.5 pt-4 rounded-lg border text-xs bg-white border-[#A1A1A1] focus:outline-none peer"
        />
        {footprintNameError && (
          <p className="text-[12px] text-[#F7716E] mt-1">
            {footprintNameError}
          </p>
        )}
        <label
          htmlFor="floatingInput1"
          className="absolute text-color-gray-light duration-300 text-xs transform -translate-y-4  scale-75 top-2 z-10 origin-[0] bg-white px-1  left-2"
        >
          Test Name
        </label>
      </div>
  

      <div className="relative w-full text-xs">
        {/* <div className="flex items-center flex-wrap gap-2 px-2 pb-2.5 pt-2 rounded-lg border border-[#A1A1A1] focus-within:outline-none">
          {formData?.tagsArray.map((tag, index) => (
            <div
              key={index}
              className="bg-gray-200 rounded-[4px] px-2 py-[2px] text-sm flex items-center"
            >
              <span>{tag}</span>
              {isEditable && (
                // <button
                //   type="button"
                //   onClick={() => removeTag(index)}
                //   className="ml-2 text-red-500 focus:outline-none"
                // >
                //   &times;
                // </button>

                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-1.5 hover:cursor-pointer" onClick={() => removeTag(index)}>
                  <path d="M1.14979 0.207469C0.88944 -0.0528803 0.46733 -0.0528803 0.206981 0.207469C-0.0533686 0.467819 -0.0533686 0.889929 0.206981 1.15028L4.0568 5.0001L0.207021 8.84988C-0.0533286 9.11023 -0.0533289 9.53234 0.207021 9.79269C0.46737 10.053 0.88948 10.053 1.14983 9.79269L4.99961 5.94291L8.8494 9.79269C9.10975 10.053 9.53186 10.053 9.79221 9.79269C10.0526 9.53234 10.0526 9.11023 9.79221 8.84988L5.94242 5.0001L9.79225 1.15028C10.0526 0.889929 10.0526 0.467819 9.79225 0.207469C9.5319 -0.0528803 9.10979 -0.0528803 8.84944 0.207469L4.99961 4.05729L1.14979 0.207469Z" fill="#616161" />
                  <path d="M1.14979 0.207469C0.88944 -0.0528803 0.46733 -0.0528803 0.206981 0.207469C-0.0533686 0.467819 -0.0533686 0.889929 0.206981 1.15028L4.0568 5.0001L0.207021 8.84988C-0.0533286 9.11023 -0.0533289 9.53234 0.207021 9.79269C0.46737 10.053 0.88948 10.053 1.14983 9.79269L4.99961 5.94291L8.8494 9.79269C9.10975 10.053 9.53186 10.053 9.79221 9.79269C10.0526 9.53234 10.0526 9.11023 9.79221 8.84988L5.94242 5.0001L9.79225 1.15028C10.0526 0.889929 10.0526 0.467819 9.79225 0.207469C9.5319 -0.0528803 9.10979 -0.0528803 8.84944 0.207469L4.99961 4.05729L1.14979 0.207469Z" fill="black" fill-opacity="0.2" />
                  <path d="M1.14979 0.207469C0.88944 -0.0528803 0.46733 -0.0528803 0.206981 0.207469C-0.0533686 0.467819 -0.0533686 0.889929 0.206981 1.15028L4.0568 5.0001L0.207021 8.84988C-0.0533286 9.11023 -0.0533289 9.53234 0.207021 9.79269C0.46737 10.053 0.88948 10.053 1.14983 9.79269L4.99961 5.94291L8.8494 9.79269C9.10975 10.053 9.53186 10.053 9.79221 9.79269C10.0526 9.53234 10.0526 9.11023 9.79221 8.84988L5.94242 5.0001L9.79225 1.15028C10.0526 0.889929 10.0526 0.467819 9.79225 0.207469C9.5319 -0.0528803 9.10979 -0.0528803 8.84944 0.207469L4.99961 4.05729L1.14979 0.207469Z" fill="black" fill-opacity="0.2" />
                  <path d="M1.14979 0.207469C0.88944 -0.0528803 0.46733 -0.0528803 0.206981 0.207469C-0.0533686 0.467819 -0.0533686 0.889929 0.206981 1.15028L4.0568 5.0001L0.207021 8.84988C-0.0533286 9.11023 -0.0533289 9.53234 0.207021 9.79269C0.46737 10.053 0.88948 10.053 1.14983 9.79269L4.99961 5.94291L8.8494 9.79269C9.10975 10.053 9.53186 10.053 9.79221 9.79269C10.0526 9.53234 10.0526 9.11023 9.79221 8.84988L5.94242 5.0001L9.79225 1.15028C10.0526 0.889929 10.0526 0.467819 9.79225 0.207469C9.5319 -0.0528803 9.10979 -0.0528803 8.84944 0.207469L4.99961 4.05729L1.14979 0.207469Z" fill="black" fill-opacity="0.2" />
                  <path d="M1.14979 0.207469C0.88944 -0.0528803 0.46733 -0.0528803 0.206981 0.207469C-0.0533686 0.467819 -0.0533686 0.889929 0.206981 1.15028L4.0568 5.0001L0.207021 8.84988C-0.0533286 9.11023 -0.0533289 9.53234 0.207021 9.79269C0.46737 10.053 0.88948 10.053 1.14983 9.79269L4.99961 5.94291L8.8494 9.79269C9.10975 10.053 9.53186 10.053 9.79221 9.79269C10.0526 9.53234 10.0526 9.11023 9.79221 8.84988L5.94242 5.0001L9.79225 1.15028C10.0526 0.889929 10.0526 0.467819 9.79225 0.207469C9.5319 -0.0528803 9.10979 -0.0528803 8.84944 0.207469L4.99961 4.05729L1.14979 0.207469Z" fill="black" fill-opacity="0.2" />
                </svg>

              )}
            </div>
          ))}
          {isEditable && (
            <input
              type="text"
              id="floatingInput2"
              name="tags"
              value={formData.tags}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder=" "
              className="flex-grow block w-auto min-w-[10px] px-2.5 py-1 border-none focus:ring-0 focus:outline-none"
            />


          )}
        </div> */}

        <div className="flex items-center flex-wrap gap-2 px-2 pb-2.5 pt-2 rounded-lg border border-[#A1A1A1] focus-within:outline-none">
          {formData?.tagsArray.map((tag, index) => (
            <div
              key={index}
              className="bg-gray-200 rounded-[4px] px-2 py-[2px] text-sm flex items-center justify-center gap-1.5"
            >
              <span>{tag}</span>
              {isEditable && (
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-1 cursor-pointer"
                  onClick={() => removeTag(index)}
                >
                  <path
                    d="M1.14979 0.207469C0.88944 -0.0528803 0.46733 -0.0528803 0.206981 0.207469C-0.0533686 0.467819 -0.0533686 0.889929 0.206981 1.15028L4.0568 5.0001L0.207021 8.84988C-0.0533286 9.11023 -0.0533289 9.53234 0.207021 9.79269C0.46737 10.053 0.88948 10.053 1.14983 9.79269L4.99961 5.94291L8.8494 9.79269C9.10975 10.053 9.53186 10.053 9.79221 9.79269C10.0526 9.53234 10.0526 9.11023 9.79221 8.84988L5.94242 5.0001L9.79225 1.15028C10.0526 0.889929 10.0526 0.467819 9.79225 0.207469C9.5319 -0.0528803 9.10979 -0.0528803 8.84944 0.207469L4.99961 4.05729L1.14979 0.207469Z"
                    fill="#616161"
                  />
                </svg>
              )}
            </div>
          ))}
          {isEditable && (
            <input
              type="text"
              id="floatingInput2"
              name="tags"
              value={formData.tags}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder=" "
              className="flex-grow block w-auto min-w-[10px] px-2.5 py-1 border-none focus:ring-0 focus:outline-none"
            />
          )}
        </div>

        {errorMessage && (
          <p className="text-[12px] text-[#F7716E] mt-1">
            {errorMessage}
          </p>
        )}

        <label
          htmlFor="floatingInput2"
          className="absolute text-color-gray-light duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] peer-focus:px-2 peer-focus:text-[#616161] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4  bg-white px-1  left-2"
        >
          Tags
        </label>
      </div>

      {/* Page URL Input */}
      {/* <div className="relative w-full text-xs">
        <input
          type="text"
          id="floatingInput3"
          name="footprintUrl"
          value={footPrint?.footprintUrl}
          disabled={isReadOnly}
          onChange={handleChange}
          placeholder=" "
          className="block w-full px-2.5 pb-2.5 pt-4 rounded-lg border border-[#A1A1A1] focus:outline-none  peer"
        />
        <label
          htmlFor="floatingInput3"
          className="absolute text-gray-700 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-[#616161] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5"
        >
          Start Page URL
        </label>
      </div> */}
      <div className="relative w-full text-xs">
        <input
          type="text"
          id="floatingInput3"
          name="footprintUrl"
          value={footPrint?.footprintUrl}
          disabled={isReadOnly}
          onChange={handleChange}
          placeholder=" "
          className="block w-full px-2.5 pb-2.5 pt-4 rounded-lg border bg-white border-[#A1A1A1] focus:outline-none peer"
        />
        <label
          htmlFor="floatingInput3"
          className="absolute text-color-gray-light duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  bg-white px-1  left-2"
        >
          Start Page URL
        </label>
        {(!isButtonEnabled && enableError) &&
          <p className="text-[12px] text-[#F7716E] mt-2 ">
            Test case url should be same as the added website url
          </p>
        }
      </div>
     
    </div>
  );
};

export default FloatingInput;
