import React, { useEffect, useState } from 'react'
import SettingButton from '../Button/SettingButton'
import axios from 'axios';
import { baseUrl } from '../../config';
import FailToast from '../Toast/FailToast';
import SuccessToast from '../Toast/SuccessToast';
import { set } from 'mongoose';

const Urls = () => {
    const [isCollapsable, setIsCollapsable] = useState("")
    const [activeFail, setActiveFail] = useState(false);
    const [activeSuccess, setActiveSuccess] = useState(false);
    const [value, setValue] = useState("");
    const [inputFields, setInputFields] = useState([""]);
    const [loading , setLoading] = useState(false)
    const [urls , setUrls] = useState([])
    const [websites, setWebsites] = useState([]);
    const userId = localStorage.getItem("userId")
    

    const collapseDropdown = (website) => {
        setIsCollapsable((prevId) => {
          if (prevId === website?._id) {
            return null;
          } else {
           
          setInputFields(website?.thirdPartyUrl?.length > 0 ? [...website?.thirdPartyUrl] : [""]);
          setUrls(website?.thirdPartyUrl?.length > 0 ? [...website?.thirdPartyUrl] : [])
            return website?._id;
          }
        });
      };
    
    const handleInputChange2 = (index, event) => {
        const values = [...inputFields];
        values[index] = event.target.value; // Update the value at the specific index
        setInputFields(values);
        setUrls(values)
    
      };
    
      const handleAddField = () => {
        const filteredFields = inputFields.filter((field) => field.trim() !== ""); // Remove empty strings
        setInputFields([...filteredFields, ""]); // Add a new empty string for a new input field
      };
    
        const handleRemoveField = (index) => {
        const values = [...inputFields];
        
        values.splice(index, 1); // Remove the value at the specified index
       
        setInputFields(values); // Update the state with the remaining URLs
        setUrls(values)
      };
    const fetchWebsites = async () => {
        try {
            const response = await axios.get(`${baseUrl}/websites/user/${userId}`);
            console.log("response from fetchwebsite", response.data.websites);
            setWebsites(response.data.websites);

        } catch (error) {
            console.log('Error fetching websites:', error.response);
        }
    };

    const AddThirdParty = async (id) => {
        const token = localStorage.getItem("Token");
        setLoading(true)
        if(urls.length > 0){
          if (token) {
            try {
              const response = await axios.put(
                `${baseUrl}/websites/${id}`,
                { thirdPartyUrl: urls},
                {
                  headers: { authorization: `Bearer ${token}` },
                }
              );
              console.log("response", response.data);
              setLoading(false)
              setActiveSuccess(true)
              setValue("Third party urls updated successfully")
              window.location.reload()
  
             
            } catch (error) {
              setLoading(false)
            }
          }
        }
        else{
          setActiveFail(true)
          setValue("Third party url must not be empty")
          setLoading(false)
          return;
        }
    
        
      };

    useEffect(() => {
        fetchWebsites()
    }, [])

     useEffect(() => {
        if (activeFail) {
          setTimeout(() => {
            setActiveFail(false);
          }, 5000);
        }
        if (activeSuccess) {
          setTimeout(() => {
            setActiveSuccess(false);
            // navigate("/dashboard");
    
          }, 3500);
        }
      }, [activeFail, activeSuccess]);
    return (
<>
{activeFail && (
        <FailToast
          errorValue={value}
          activeFail={activeFail}
          setActiveFail={setActiveFail}
        />
      )}
      {activeSuccess && (
        <SuccessToast
          successValue={value}
          activeSuccess={activeSuccess}
          setActiveSuccess={setActiveSuccess}
        />
      )}

        <div class="h-full  ">
            <div className='bg-white h-full  border border-layout-border shadow-[0px_1px_2px_0px_#0000000D] rounded-lg py-6 px-10'
          
            >
                <div className='font-semibold text-3xl'>
                Third party URLs
                </div>
                <div className='font-medium text-sm text-color-gray-light pt-1'>
                    If your website has parts that send the user to another website, enter those domains here. Example: Payment gateways URLs. stripe.com
                </div>
                <div className="">
                    <div className="overflow-y-scroll h-screen mt-8 px-1">
                {websites?.length>0  && websites.map((website) => {
                    return (
                        <div className="flex flex-col   p-3 rounded-md border border-link-border mb-6" key={website?._id} >
                            <div className="flex items-center justify-between">


                                <div className="flex flex-row space-x-4">

                                    <div className='flex justify-center items-center'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 12H8M3 12C3 16.9706 7.02944 21 12 21M3 12C3 7.02944 7.02944 3 12 3M8 12H16M8 12C8 16.9706 9.79086 21 12 21M8 12C8 7.02944 9.79086 3 12 3M16 12H21M16 12C16 7.02944 14.2091 3 12 3M16 12C16 16.9706 14.2091 21 12 21M21 12C21 7.02944 16.9706 3 12 3M21 12C21 16.9706 16.9706 21 12 21" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                                    </div>
                                    <div className='flex flex-col space-y-[6px]'>
                                        <span className="text-color-gray-light font-semibold text-base">
                                            {website?.url}
                                        </span>
                                        <span className="font-normal text-xs text-input-remark">
                                        Add the list of third party websites you send your users to.
                                        </span>
                                    </div>

                                </div>

                                <div className="flex justify-center items-center space-x-2 cursor-pointer" >

                            
{website?.thirdPartyUrl?.length>0 &&
<div className='border border-[#D1FADF] py-1 px-3 bg-[#ECFDF3] rounded-full'>
    <div className='font-semibold text-xs text-[#039855]'>{website?.thirdPartyUrl?.length} Url</div>
</div>
}

                                    {isCollapsable === website?._id ? <svg onClick={()=>collapseDropdown(website)} width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 8L8 1L15 8" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                        :
                                        <svg onClick={()=>collapseDropdown(website)} width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 1L8 8L1 1" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    }
                                </div>
                            </div>

                            {isCollapsable === website?._id && (
                                  
                                    <div className="mt-2 mb-2">
                                      {inputFields.map((value, index) => (
                                        <div key={index} className="flex items-center space-x-2 pb-2">
                                      
                                        <input
                                             type="text"
                                             value={value}
                                             onChange={(event) => handleInputChange2(index, event)}
                                             placeholder={`Enter URL ${index + 1}`}

                                            className="bg-white border text-color-gray-light sm:text-sm rounded-lg focus:outline-none   block w-full p-3 " />

                                          <button
                                            type="button"
                                            onClick={() => handleRemoveField(index)}
                                            disabled={inputFields.length === 1} // Disable if only one field remains
                                            className="w-6 h-6  text-white flex items-center cursor-pointer justify-center  focus:outline-none focus:ring-2 focus:ring-[#E4E4E4]"
                                          >
                                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 4V15.8C3 16.9201 3 17.4798 3.21799 17.9076C3.40973 18.2839 3.71547 18.5905 4.0918 18.7822C4.5192 19 5.07899 19 6.19691 19H11.8031C12.921 19 13.48 19 13.9074 18.7822C14.2837 18.5905 14.5905 18.2839 14.7822 17.9076C15 17.4802 15 16.921 15 15.8031V4M3 4H5M3 4H1M5 4H13M5 4C5 3.06812 5 2.60241 5.15224 2.23486C5.35523 1.74481 5.74432 1.35523 6.23438 1.15224C6.60192 1 7.06812 1 8 1H10C10.9319 1 11.3978 1 11.7654 1.15224C12.2554 1.35523 12.6447 1.74481 12.8477 2.23486C12.9999 2.6024 13 3.06812 13 4M13 4H15M15 4H17" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                                          </button>
                                        </div>
                                      ))}
                      
                                      
                                      <div className='flex flex-end mt-1 '>
                                            {/* <SettingButton text={"+"} 
                                                onClick={handleAddField} 
                                            /> */}

<button
onClick={handleAddField} 
                className={` inline-flex items-center justify-center py-2 px-3 text-sm font-semibold focus:outline-none rounded border bg-[#EAECF0]`} 
            >
             <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
<path d="M1.5 5H5M5 5H8.5M5 5V8.5M5 5V1.5" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>  Add Website
               
            </button>
                                           
                                        </div>

                                        <div className='flex justify-end  space-x-2'>
                                            <SettingButton text={"Save"} onClick={()=>AddThirdParty(website?._id)}
                                            loading={loading}
                                            />
                                           
                                        </div>
                      
                      
                                    </div>
                                  
                            )}
                        </div>
                    )

                })}
                </div>
</div>


            </div>




        </div>
        </>
    )
}


export default Urls