import React, { useState, useEffect } from "react";
import { Table, Pagination, Checkbox } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import axios from "axios";
import SuccessToast from "../Toast/SuccessToast";
import { Dropdown, Menu, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../config";
import FailToast from "../Toast/FailToast";
import Test_Video_Summary from "../Footprint/Test_Video_Summary";

// Custom pagination component
// const CustomPagination = ({
//     current,
//     pageSize,
//     total,
//     onChange,
//     onPageSizeChange,
// }) => {
//     return (
//         <div className="flex items-center justify-between p-4">
//             <div className="text-sm text-gray-700">
//                 Showing{" "}
//                 <b>
//                     {(current - 1) * pageSize + 1}-{Math.min(current * pageSize, total)}
//                 </b>{" "}
//                 of {total} records
//             </div>
//             <div className="flex items-center space-x-2">
//                 <select
//                     value={pageSize}
//                     onChange={(e) => onPageSizeChange(parseInt(e.target.value, 10))}
//                     className="rounded px-2 py-1 text-sm"
//                 >
//                     <option value={10}>Show 10 rows</option>
//                     <option value={20}>20 rows</option>
//                     <option value={50}>50 rows</option>
//                 </select>
//                 <Pagination
//                     current={current}
//                     pageSize={pageSize}
//                     total={total}
//                     onChange={onChange}
//                     hideOnSinglePage
//                     showSizeChanger={false}
//                     prevIcon={
//                         <div className="bg-[#E4E4E4] text-white rounded-md">
//                             <LeftOutlined />
//                         </div>
//                     }
//                     nextIcon={
//                         <div className="bg-[#E4E4E4] text-white rounded-md">
//                             <RightOutlined />
//                         </div>
//                     }
//                     className="ant-pagination"
//                     itemRender={(current, type, originalElement) => {
//                         if (type === "prev") {
//                             return (
//                                 <div className="bg-[#E4E4E4] text-black rounded-md">
//                                     <LeftOutlined />
//                                 </div>
//                             );
//                         }
//                         if (type === "next") {
//                             return (
//                                 <div className="bg-[#E4E4E4] text-black rounded-md">
//                                     <RightOutlined />
//                                 </div>
//                             );
//                         }
//                         return "";
//                     }}
//                 />
//             </div>
//         </div>
//     );
// };


const CustomPagination = ({ current, pageSize, total, onChange }) => {
    const totalPages = Math.ceil(total / pageSize);

    return (
        <div className="flex items-center justify-between p-4">
            <div className="flex items-center justify-between w-full">
                {/* Previous Button */}
                <div
                    className={`flex items-center text-color-gray-light text-xl font-semibold space-x-2 py-1 cursor-pointer ${current === 1 ? "opacity-50 pointer-events-none" : ""
                        }`}
                    onClick={() => current > 1 && onChange(current - 1)}
                >
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.92259 1.5886C7.24803 1.26317 7.24803 0.73553 6.92259 0.410093C6.59715 0.0846564 6.06951 0.0846564 5.74408 0.410093L0.744078 5.41009C0.418641 5.73553 0.418641 6.26317 0.744078 6.5886L5.74408 11.5886C6.06951 11.914 6.59715 11.914 6.92259 11.5886C7.24803 11.2632 7.24803 10.7355 6.92259 10.4101L3.34518 6.83268L14.6667 6.83268C15.1269 6.83268 15.5 6.45959 15.5 5.99935C15.5 5.53911 15.1269 5.16602 14.6667 5.16602L3.34518 5.16602L6.92259 1.5886Z"
                            fill="#111322"
                        />
                    </svg>
                    <span>Previous</span>
                </div>

                {/* Pagination Numbers */}
                <div className="flex items-center space-x-2">
                    <Pagination
                        current={current}
                        pageSize={pageSize}
                        total={total}
                        onChange={onChange}
                        hideOnSinglePage
                        showSizeChanger={false}


                        className="custom-pagination"

                        itemRender={(page, type, originalElement) => {
                            if (type === "page") {
                                return (
                                    <div
                                        className={`w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${current === page
                                            ? "bg-primary-button text-white"
                                            : "bg-white font-medium border border-layout-border text-[16px] text-footer"
                                            }`}
                                    >
                                        {page}
                                    </div>
                                );
                            }
                            return null; // Completely removes prev and next buttons

                            //   return originalElement;
                        }}
                    />
                </div>

                {/* Next Button */}
                <div
                    className={`flex items-center space-x-2 text-color-gray-light text-xl font-semibold py-1 cursor-pointer ${current === totalPages ? "opacity-50 pointer-events-none" : ""
                        }`}
                    onClick={() => current < totalPages && onChange(current + 1)}
                >
                    <span>Next</span>
                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.2559 0.410093C9.93049 0.0846564 9.40285 0.0846564 9.07741 0.410093C8.75197 0.73553 8.75197 1.26317 9.07741 1.5886L12.6548 5.16602H1.33333C0.873096 5.16602 0.5 5.53911 0.5 5.99935C0.5 6.45959 0.873096 6.83268 1.33333 6.83268H12.6548L9.07741 10.4101C8.75197 10.7355 8.75197 11.2632 9.07741 11.5886C9.40285 11.914 9.93049 11.914 10.2559 11.5886L15.2559 6.5886C15.5814 6.26317 15.5814 5.73553 15.2559 5.41009L10.2559 0.410093Z"
                            fill="#111322"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};


const Test_Video_Table = ({

    websiteId, footprintId,
     filter, handleCount ,searchText
}) => {
    const [activeFail, setActiveFail] = useState(false);
    const [activeSuccess, setActiveSuccess] = useState(false);
    const [value, setValue] = useState("");
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [dataSource, setDataSource] = useState([]);
    const navigate = useNavigate();





    const [limitedDataSource, setLimitedDataSource] = useState([]);
    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay)
        }
    }



















    const handleRowClick = (record) => {
        // console.log("Row clicked for normal columns:", record, rowIndex);
        const data = { footprintId: record.key, websiteId: websiteId };
        record.status === "inactive" ? navigate(`/Savefootprint/${record.key}/website/${websiteId}`, { state: { Back: false } }) : navigate(`/${websiteId}/${record.key}/test-info`, { state: data });

        // navigate(`/footprint-list`, { state: data });
    };



    useEffect(() => {
        if (activeFail) {
            setTimeout(() => {
                setActiveFail(false);
            }, 3000);
        }
        if (activeSuccess) {
            setTimeout(() => {
                setActiveSuccess(false);
                const data = { websiteId: websiteId };
                navigate("/footprint-status-list", { state: data });
            }, 2000);
        }
    }, [activeFail, activeSuccess]);



    const [sortOrder, setSortOrder] = useState("ascend"); // Track the sort order

    const handleSort = (dataIndex) => {
        const newSortOrder = sortOrder === "ascend" ? "descend" : "ascend";

        // Sort the data
        const sortedData = [...dataSource].sort((a, b) => {
            if (newSortOrder === "ascend") {
                return a[dataIndex].localeCompare(b[dataIndex]);
            } else {
                return b[dataIndex].localeCompare(a[dataIndex]);
            }
        });

        // Add the dynamic footprint
        // const sortedWithFootprint = sortedData.map((item, index) => ({
        //     ...item,
        //     footprint: `TT #${index + 1}`, // Dynamically assign footprint based on the sorted index
        // }));

        // Update state
        setSortOrder(newSortOrder);
        setDataSource(sortedData);

    };

    // Define columns with the new headers and checkbox
    const columns = [
        {
            title: <div className="header-section flex items-center gap-3 ">

                <span className="text-input-remark">Run #
                </span>
                <svg className="mt-0.5 cursor-pointer" style={{
                    transform: sortOrder === "ascend" ? "rotate(0deg)" : "rotate(180deg)", // Rotate based on sortOrder
                    transition: "transform 0.3s ease", // Smooth transition for rotation
                }} onClick={() => handleSort("run")}
                    width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg"
                >


                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9999 0.599609C4.22082 0.599609 4.3999 0.778697 4.3999 0.999609V8.03393L6.91702 5.51677C7.07326 5.36055 7.32654 5.36055 7.48278 5.51677C7.63894 5.67298 7.63894 5.92624 7.48278 6.08245L4.28274 9.28249C4.20774 9.35745 4.10599 9.39961 3.9999 9.39961C3.89382 9.39961 3.79207 9.35745 3.71706 9.28249L0.517064 6.08245C0.360848 5.92624 0.360848 5.67298 0.517064 5.51677C0.673272 5.36055 0.926536 5.36055 1.08274 5.51677L3.5999 8.03393V0.999609C3.5999 0.778697 3.77899 0.599609 3.9999 0.599609Z" fill="#5D6B98" />
                </svg>
            </div>,
            dataIndex: "run",
            key: "run",
            render: (_, text, record) => {


                return (
                    <div className="flex items-center">

                        <span className="font-bold text-sm text-input-remark">
                            {text?.run}
                        </span>
                    </div>
                )
            },
        },


        {
            title: (
                <div className="header-section flex items-center gap-3">
                    <span className="text-input-remark">Run Date & Time</span>
                    <svg
                        className="mt-0.5 cursor-pointer"
                        width="8"
                        height="10"
                        viewBox="0 0 8 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    // onClick={() => handleSort("date")} // Trigger custom sort
                    // style={{
                    //     transform: sortOrder === "ascend" ? "rotate(0deg)" : "rotate(180deg)", // Rotate based on sortOrder
                    //     transition: "transform 0.3s ease", // Smooth transition for rotation
                    // }}
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.9999 0.599609C4.22082 0.599609 4.3999 0.778697 4.3999 0.999609V8.03393L6.91702 5.51677C7.07326 5.36055 7.32654 5.36055 7.48278 5.51677C7.63894 5.67298 7.63894 5.92624 7.48278 6.08245L4.28274 9.28249C4.20774 9.35745 4.10599 9.39961 3.9999 9.39961C3.89382 9.39961 3.79207 9.35745 3.71706 9.28249L0.517064 6.08245C0.360848 5.92624 0.360848 5.67298 0.517064 5.51677C0.673272 5.36055 0.926536 5.36055 1.08274 5.51677L3.5999 8.03393V0.999609C3.5999 0.778697 3.77899 0.599609 3.9999 0.599609Z"
                            fill="#5D6B98"
                        />
                    </svg>
                </div>
            ),
            dataIndex: "date",
            key: "date",
            render: (text) => <span className="text-sm font-normal text-input-remark ">{text.date}</span>,
        },

        {
            title:

                <div className="header-section flex items-center gap-3 ">

                    <span className="text-input-remark">Result
                    </span>
                    <svg className="mt-0.5" width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.9999 0.599609C4.22082 0.599609 4.3999 0.778697 4.3999 0.999609V8.03393L6.91702 5.51677C7.07326 5.36055 7.32654 5.36055 7.48278 5.51677C7.63894 5.67298 7.63894 5.92624 7.48278 6.08245L4.28274 9.28249C4.20774 9.35745 4.10599 9.39961 3.9999 9.39961C3.89382 9.39961 3.79207 9.35745 3.71706 9.28249L0.517064 6.08245C0.360848 5.92624 0.360848 5.67298 0.517064 5.51677C0.673272 5.36055 0.926536 5.36055 1.08274 5.51677L3.5999 8.03393V0.999609C3.5999 0.778697 3.77899 0.599609 3.9999 0.599609Z" fill="#5D6B98" />
                    </svg>
                </div>,
            dataIndex: "status",
            key: "status",
            render: (text) => {
                console.log(text, "ye test hai");
                return (
                    <div className="flex items-center">
                        {/* Capsule with dropdown inside */}
                        <div
                            className={`flex items-center justify-between px-3 py-1 rounded-full text-xs font-semibold  w-max ${text === "Pass"
                                ? "bg-green text-green border border-green "
                                : "bg-error-video text-error border border-error"
                                }`}
                        >
                            {/* Status Text */}


                            <span className={`${text === "Pass" ? "text-green-600" : "text-red-600"
                                }`}>{text}</span>

                        </div>
                    </div>
                )

            },
        },
        {
            title:
                <div className="header-section flex items-center gap-3 ">

                    <span className="text-input-remark">View Run
                    </span>

                </div>,
            dataIndex: "records",
            key: "records",
            render: (text) => (
                <div className="text-sm font-bold">
                    <span className="text-link cursor-pointer">{text}</span>
                </div>
            ),
        },





        {
            title: <span className="text-input-remark"></span>,
            dataIndex: "successfulRuns",
            key: "successfulRuns",
            render: (text, record) => {
                const isExpanded = expandedRowKeys.includes(record.key); // Check if the row is expanded
                return (
                    <div className="flex items-center">
                        <svg
                            width="14"
                            height="9"
                            className={`cursor-pointer transform transition-transform duration-300 ${isExpanded ? "rotate-180" : "rotate-0"
                                }`}
                            viewBox="0 0 14 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.8332 1.5L6.99984 7.33333L1.1665 1.5"
                                stroke="#111322"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                );
            },
        },

    ];


    ///======>
    const formatDateTime = (isoString) => {
        const date = new Date(isoString);

        const options = { month: "short", day: "numeric" };
        const formattedDate = date.toLocaleDateString("en-US", options);

        const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };
        const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

        const dayName = date.toLocaleDateString("en-US", { weekday: "long" });

        return {
            date: `${formattedDate}, at ${formattedTime}`,
            dayName,
        };
    };



    // Handle checkbox changes
    const handleCheckboxChange = (key, checked) => {
        // Update the state or perform necessary actions
        console.log(`Row ${key} checkbox changed to ${checked}`);
    };




    useEffect(() => {
        if (dataSource && dataSource.length > 0) {
            setLimitedDataSource(dataSource.slice(0, 4));
        }
    }, [dataSource]);
    useEffect(() => {


        const fetchData = async () => {
            const response = await axios.get(
                `${baseUrl}/footprint/foot/${footprintId}`
            ); // Adjust the API endpoint
            const footprint = response.data;

            console.log(footprint, "check krna hai")
            const payload = {};
            payload.successCount = footprint.successCount;
            payload.failureCount = footprint.failureCount;
            handleCount(payload);

            // Map the footprint data to the table structure
            const data = footprint?.videos?.map((video, index) => {
                const { date, dayName } = formatDateTime(video.createdAt);
                return {
                    key: video?._id,
                    run: `Run #${index + 1}`,
                    date: { date, dayName },
                    status: video.status === "success" ? "Pass" : "Fail",

                    // status: video?.status === "success" ? "Pass" : video?.status==="partial_success"? "Partial Success" : video?.status==="partial_failure" ? "Partial Failed" : "Fail",
                    records: "View Run",
                    videoUrl: video?.url,
                    logUrl: video?.logUrl,
                };
            });


            setDataSource(data);
        };
        fetchData();
    }, []);







    //-----> 


    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(
                // "http://localhost:5000/api/footprint/foot/66bae9dc19babe204a466f1c"
                `${baseUrl}/footprint/foot/${footprintId}`
            ); // Adjust the API endpoint
            const footprint = response.data;

            const data = footprint.videos.map((video, index) => {
                const { date, dayName } = formatDateTime(video.createdAt);
                return {
                    key: video._id,
                    run: `Run #${index + 1}`,
                    date: { date, dayName },
                    status: video.status === "success" ? "Pass" : "Fail",
                    // status: video?.status === "success" ? "Pass" : video?.status === "partial_success" ? "Partial Success" : video?.status === "partial_failure" ? "Partial Failed" : "Fail",

                    records: "View Run",
                    videoUrl: video.url,
                    logUrl: video?.logUrl,
                };
            });

            setDataSource(data);
        };

        fetchData();
    }, []);

    // const filteredDataSource = dataSource?.filter((item) => {
    //     if (filter === "All") return true;

    //     return item.status === filter;
    // });

    // Filter data based on searchTerm and status
    const filteredDataSource = dataSource?.filter((item) => {
        // Filter by status if a filter is applied
        const statusMatch = filter === "All" || item.status === filter;

        // Filter by search term (Run #)
        const runMatch = item.run.toLowerCase().includes(searchText.toLowerCase());

        return statusMatch && runMatch;
    });

    const onExpand = (expanded, record) => {
        const keys = expanded ? [record.key] : [];
        setExpandedRowKeys(keys);
    };

    const expandedRowRender = (record) => {
        return (

            <Test_Video_Summary videoUrl={record?.videoUrl}
                logUrl={record?.logUrl}
                websiteId={websiteId}
                footprintId={footprintId}
                videoId={record?.key} />




        );
    };

    const handlePageChange = (page) => {
        setPagination((prev) => ({ ...prev, current: page }));
    };

    const handlePageSizeChange = (size) => {
        setPagination((prev) => ({ ...prev, pageSize: size, current: 1 }));
    };










    console.log("web", websiteId, "foot", dataSource)





    return (
        <div className="">
            {activeSuccess && (
                <SuccessToast
                    successValue={value}
                    activeSuccess={activeSuccess}
                    setActiveSuccess={setActiveSuccess}
                />
            )}
            {activeFail && (
                <FailToast
                    errorValue={value}
                    activeFail={activeFail}
                    setActiveFail={setActiveFail}
                />
            )}
            <Table
                columns={columns}
                dataSource={filteredDataSource.slice(
                    (pagination.current - 1) * pagination.pageSize,
                    pagination.current * pagination.pageSize
                )}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => {
                            // console.log("Row clicked:", record, rowIndex);
                            // const data = { footprintId: record.key, websiteId: websiteId };
                            // navigate(`/footprint-list`, { state: data });
                            // Perform any action here, like navigating to another page or opening a modal
                            // handleRowClick(record, rowIndex);
                        },
                    };
                }}
                pagination={false}
                bordered={false} // Ensure `bordered` is set to false
                className="custom-table"


                // rowClassName={(record, index) =>
                //     record.status === "Fail" ? "bg-[#FCD0CF] bg-opacity-50" : ""
                // }

                rowClassName={(record, index) =>
                    expandedRowKeys.includes(record.key) // Check if row is expanded
                        ? "expanded-row" // Add a class to expanded rows
                        : ""
                }
                expandable={{
                    expandedRowRender,
                    expandedRowKeys,
                    onExpand,
                    expandRowByClick: true,
                }}
                components={{
                    header: {
                        cell: (props) => (
                            <th
                                {...props}
                                className="!bg-layout text-input-remark !font-semibold text-xs"
                            />
                        ),
                    },
                }}

                expandIconColumnIndex={-1}
            />

            {
                filteredDataSource?.length > 10 && (
                    <CustomPagination
                        current={pagination.current}
                        pageSize={pagination.pageSize}
                        total={filteredDataSource.length}
                        onChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                )
            }




        </div>
    );
};

export default Test_Video_Table;
