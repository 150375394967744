
import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Nav from "../Navbar/Nav";




const Layout = () => {
  const location = useLocation();
 

  // useEffect(() => {
  //   const handleLoad = () => {
  //     console.log("Page fully loaded");

  //     const hasShownConfirmation = localStorage.getItem("hasShownConfirmation");
  //     const messageListener = (event) => {
  //       if (event.source !== window) return;
  //       if (event.data.type === "EXTENSION_INSTALLED") {
  //         console.log("Extension is installed:", event.data.data);         

  //         setIsExtensionInstalled(true);
          
          
  //         if (!hasShownConfirmation) {
  //           setShowExtensionConfirm(true);
  //         }
  //       }
  //     };

  //     // Add event listener for messages
  //     window.addEventListener("message", messageListener);

  //     // Send a message to check if the extension is installed
  //     console.log("Sending CHECK_EXTENSION message");
  //     window.postMessage({ type: "CHECK_EXTENSION" }, "*");

  //     // Clean up event listener on component unmount
  //     return () => {
  //       window.removeEventListener("message", messageListener);
  //     };
  //   };

  //   // Check if document is already loaded
  //   if (document.readyState === 'complete') {
  //     handleLoad();
  //   } else {
  //     window.addEventListener('load', handleLoad);

  //     // Clean up the load event listener
  //     return () => {
  //       window.removeEventListener('load', handleLoad);
  //     };
  //   }
  // }, []);

  


  // useEffect(() => {
  //   const handleLoad = () => {
  //     console.log("Page fully loaded");
  
  //     const hasShownConfirmation = localStorage.getItem("hasShownConfirmation");
  //     const messageListener = (event) => {
  //       if (event.source !== window) return;
  
  //       if (event.data.type === "EXTENSION_INSTALLED") {
  //         console.log("Extension is installed:", event.data.data);
  
  //         // Update state and localStorage
  //         setIsExtensionInstalled(true);
  //         localStorage.setItem("IsExtensionInstalled", "true"); // Store true in localStorage
  
  //         if (!hasShownConfirmation) {
  //           setShowExtensionConfirm(true);
  //           localStorage.setItem("hasShownConfirmation", "true"); // Mark confirmation as shown
  //         }
  //       } else {
  //         // If extension is not installed, set false in localStorage
  //         setIsExtensionInstalled(false);
  //         localStorage.setItem("IsExtensionInstalled", "false");
  //       }
  //     };
  
  //     // Add event listener for messages
  //     window.addEventListener("message", messageListener);
  
  //     // Send a message to check if the extension is installed
  //     console.log("Sending CHECK_EXTENSION message");
  //     window.postMessage({ type: "CHECK_EXTENSION" }, "*");
  
  //     // Clean up event listener on component unmount
  //     return () => {
  //       window.removeEventListener("message", messageListener);
  //     };
  //   };
  
  //   // Check if document is already loaded
  //   if (document.readyState === "complete") {
  //     handleLoad();
  //   } else {
  //     window.addEventListener("load", handleLoad);
  
  //     // Clean up the load event listener
  //     return () => {
  //       window.removeEventListener("load", handleLoad);
  //     };
  //   }
  // }, []);
  



  const hideSidebarPaths = ["/onboarding", "/addWebsite"];
  const shouldHideSidebar = hideSidebarPaths.includes(location.pathname);

  // useEffect(() => {
  //   fetchUserById()


  //   handleSaveStatus()



  // }, [isExtensionInstalled])

  return (
    <div className="flex flex-col min-h-screen bg-layout">

      <Nav />

      {/* <div className=" mx-20 py-2 gap-6 flex  flex-1 overflow-hidden">
        {!isExtensionInstalled && !shouldHideSidebar && <ExtensionAlert />}
      </div> */}
      {/* Content area with sidebar and main content mx-16 */}
      <div className="outer-section mx-20 py-6 gap-6 flex  flex-1 overflow-hidden ">

        {!shouldHideSidebar && <Sidebar />}

        <main className="flex-1   overflow-y-auto">
          {/* {children} */}
          <Outlet />
        </main>

      </div>

      <Footer />
    </div>
  );
};

export default Layout;



