import React, { useState, useEffect } from "react";
import Test_Schedule_Card from "../Card/Test_Schedule_Card";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import Test_Home_Card from "../Card/Test_Home_Card";
import axios from 'axios';
import Test_Figures from "./Test_Figures";
import ChartComponent from "../Chart/CharComponent";
import Alert_Card from "../Card/Alert_Card";
import Empty_Screen_Card from "../Card/Empty_Screen_Card";
import monitorImage from "../Images/monitorImage.png"
import RechartsPieChart from "../Chart/RechartsPieChart";
import EcgGraph from "../Chart/EcgGraph";

const Dashboard = () => {
    const [figures, setFigures] = useState({
        totalActiveCount: "",
        totalCompleteCount: "",
        totalInactiveCount: "",
        totalPausedCount: ""
    });
    const [websiteUrl, setWebsiteUrl] = useState();

    const { state } = useLocation();
    const [footprints, setFootprints] = useState([]);
    const { websiteId } = useParams()

    const fetchMonitoredFootprints = async () => {
        try {
            const response = await axios.get(`${baseUrl}/websites/${websiteId}/monitor`);
            console.log(response.data, "hdid")
            setFootprints(response.data.data);
            setWebsiteUrl(response.data.url);

        } catch (error) {
            console.log(error);
        }

    }

    // const websiteId = localStorage.getItem("selectedWebsiteId");
    console.log('ye nh aaya', websiteId)

    const fetchFootprintCountStatus = async () => {
        try {
            const response = await axios.get(
                `${baseUrl}/websites/${websiteId}/count-status`
            );
            console.log("response from footprint by Id", response.data);
            setFigures(response.data);
        } catch (error) {
            console.log("Error in fetching data", error.response);
        }
    };

    useEffect(() => {
        if (websiteId) {
            fetchFootprintCountStatus();
            fetchMonitoredFootprints();
        }
    }, [websiteId]);


    const processFootprintVideos = (footprints) => {
        return footprints.map((footprint) => {
            return {
                footprintId: footprint._id, // Assuming footprints have an _id
                footprintName: footprint.name,
                videos: footprint.videos.map((video) => {
                    const istTime = new Date(video.createdAt).toLocaleString("en-IN", {
                        timeZone: "Asia/Kolkata",
                    });
                    return {
                        createdTime: istTime,
                        status: video.status,
                    };
                }),
            };
        });
    };


    // Process the data
    const formattedData = processFootprintVideos(footprints);

    // Log the output
    console.log(JSON.stringify(formattedData, null, 2));
    const videoData = [
        { time: 4, result: 1 }, // Pass at 4h
  { time: 12, result: -1 }, // Fail at 12h
  { time: 20, result: 1 }, // Pass at 20h
      ];

    // const videoData = [
    //     { status: "neutral" },
    //     { status: "pass" },
    //     { status: "neutral" },
    //     { status: "fail" },
    //   ];
    return (
        <>
            <div className="header-section">

                <div className="info border border-layout-border p-6 rounded-lg bg-white flex flex-col gap-6">
                    <div className="title-section ">
                        <div className="title font-semibold text-3xl text-color-gray-light">Dashboard</div>
                        <div className="inner-content text-sm font-medium text-color-gray-light">Here is a summary for {state?.url || websiteUrl}</div>

                    </div>
                    <div className="figures-sections">
                        <Test_Figures figures={figures}  />
                    </div>


                    <div className="monitoring-section ">
                        <div className="title font-semibold text-3xl text-color-gray-light">Monitoring</div>
                        <div className="inner-content text-sm font-medium text-color-gray-light">You have added the following tests in monitoring</div>
                        {/* <div className="container my-6"> */}

                        <div className="info border border-layout-border p-6 rounded-lg bg-layout shadow-[0px_1px_2px_0px_#0000000D] flex flex-col gap-6 my-6">



                            {
                                formattedData.length > 0 ?

                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                        {Array.isArray(formattedData) &&
                                            formattedData.map((footprint, index) => (
                                                <div key={index} className="bg-white rounded-lg">
                                                    <ChartComponent videos={footprint.videos} footprintName={footprint.footprintName} />
                                                </div>
                                            ))


                                        }
                                    </div>


                                    :
                                    <div className="empty-screen my-[60px]">
                                        <Empty_Screen_Card image={monitorImage} to={`/${websiteId}/test-list`} buttonName="Go to Test lists page" mainContent="No Tasks to Monitor" subContent={<>
                                            You can add tasks from the test list <br />
                                            page to monitor here.
                                        </>} />
                                    </div>
                            }


{/* <EcgGraph data={videoData} /> */}










                        </div>






                    </div>

                </div>
            </div>
        </>
    );
};

export default Dashboard;
