import React, { useEffect, useState } from "react";
import { FaRegCircle, FaCheckCircle } from "react-icons/fa";

const Guide2_Input = ({ onInputChange, activeFail, data, errorWebsiteAdded, setErrorWebsiteAdded , setUrls }) => {
  const [inputValue, setInputValue] = useState("");
  const [isFilled, setIsFilled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputFields, setInputFields] = useState([""]); // Array to store URLs
  const [showDynamicForm, setShowDynamicForm] = useState(false)
  // DYNAMIC FORM



  // Function to handle input value changes
  const handleInputChange2 = (index, event) => {
    const values = [...inputFields];
    values[index] = event.target.value; // Update the value at the specific index
    setInputFields(values);
    setUrls(values)

  };

  const handleAddField = () => {
    const filteredFields = inputFields.filter((field) => field.trim() !== ""); // Remove empty strings
    setInputFields([...filteredFields, ""]); // Add a new empty string for a new input field
    console.log("inputFiled",inputFields)
  };

    const handleRemoveField = (index) => {
    const values = [...inputFields];
    values.splice(index, 1); // Remove the value at the specified index
    setInputFields(values); // Update the state with the remaining URLs
    setUrls(values)
  };

 


  const validateInput = (value) => {
    // const domainPattern = /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/; // Basic pattern for domain validation
    const domainPattern  = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/;

    const existingDomains = ["google.com", "example.com"]; // Add the list of domains already in use here

    if (existingDomains.includes(value)) {
      setErrorMessage(
        "That website is already part of your or someone else's account."
      );
      setIsFilled(false);
    } else if (!domainPattern.test(value)) {
      setErrorMessage("That doesn't look like a website url.");
      setIsFilled(false);
    } else {
      setErrorMessage("");
      setIsFilled(true);
    }
  };

  const handleInputChange = (e) => {
    let value = e.target.value.trim();
    value = value
      .replace(/^https?:\/\/(www\.)?|^www\./, "")
      .replace(/\/+$/, "");
    setInputValue(value);
    validateInput(value);
    setErrorWebsiteAdded("")
    onInputChange(value);
  };

  useEffect(() => {
    if (data) {
      setInputValue(data); // Set the input value if data exists
    }
  }, [data]);

  useEffect(() => {
    if (activeFail) {
      setErrorMessage("Please enter your website url");
    }
  }, [activeFail]);
  return (
    <>
      <div className="p-1">
        <div className="flex flex-col ">
          <div className="flex items-center ml-2 mt-4">
            <button className="focus:outline-none">

              <FaRegCircle className="text-gray-500" />
            </button>
            <div className="text-color-gray-light font-extrabold text-lg mx-2 ">
              Enter your website URL
            </div>
          </div>

          <div className="input-info mx-2">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Enter your website URL"
              className={`w-full pl-2 pr-4 py-2 rounded-lg border ${errorMessage ? "" : "border-[#A1A1A1]"
                } focus:outline-none focus:border-[#A1A1A1] my-2`}
            />
            <p className="text-xs mx-2">
              Example: google.com. Do not enter sub-domains or https:// or www.
            </p>
            {!isFilled && errorMessage && (
              <p className="text-[12px] mx-2 text-xs text-[#F7716E]">
                {errorMessage}
              </p>



            )}
            <p className="text-[12px] mx-2 text-xs text-[#F7716E]">
              {errorWebsiteAdded}
            </p>

            <div className="flex justify-start items-center space-x-2 pt-2 ">
              
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShowDynamicForm(true)} className="text-white cursor-pointer" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>
           
              <p className="  items-center text-base font-semibold  " >
                Add third party domains (optional)
              </p>
            </div>


            {/* DYNAMIC FORM */}

            {showDynamicForm &&
              <div className="mt-2 mb-2">
                {inputFields.map((value, index) => (
                  <div key={index} className="flex items-center space-x-2 pb-2">
                    {/* Input field */}
                    <input
                      type="text"
                      value={value}
                      onChange={(event) => handleInputChange2(index, event)}
                      className="w-3/4 pl-2 pr-4 py-2 rounded-lg border  focus:outline-none focus:border-[#A1A1A1] "
                      placeholder={`Enter URL ${index + 1}`}
                    />
                    {/* Remove button */}
                    <button
                      type="button"
                      onClick={() => handleRemoveField(index)}
                      disabled={inputFields.length === 1} // Disable if only one field remains
                      className="w-6 h-6 bg-[#E4E4E4] text-white flex items-center cursor-pointer justify-center rounded-full hover:bg-[#E4E4E4] bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-[#E4E4E4]"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128Z"></path></svg>
                    </button>
                  </div>
                ))}

                <button
                  type="button"
                  onClick={handleAddField}
                  className="w-8 h-8 bg-[#E4E4E4] text-white flex items-center justify-center rounded-lg hover:bg-[#E4E4E4] bg-opacity-50focus:outline-none focus:ring-2 focus:ring-[#E4E4E4]"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="text-white" width="20" height="20" fill="#000000" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>
                </button>


              </div>
            }

            {/* END DYNAMIC FORM */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Guide2_Input;
