import axios from 'axios';
import { baseUrl } from '../../config';


const register=async(userData)=>{
    const response =await axios.post(`${baseUrl}/user/register` ,userData);
    if(response.data) {
        return response.data;
    }
}

const login=async(userData)=>{
    const response =await axios.post(`${baseUrl}/user/login` ,userData);
    console.log(response)
    if(response.data) {
        return response.data;
    }
}


export const authService ={
    login, register
}