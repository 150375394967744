

//--------------------> dynamic data 
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import RadialBarChart from './RadialBarChart'; // Assuming you have this component
import RechartsPieChart from './RechartsPieChart';

const ApexChart = ({ figures }) => {
  const {
    totalActiveCount = 0,
    totalInactiveCount = 0,
    totalCompleteCount = 0,
    totalPausedCount = 0,
  } = figures;

  const totalCount =
    totalActiveCount + totalInactiveCount + totalCompleteCount + totalPausedCount;

  console.log('Data', totalCount);

  const data = [
    { label: 'Complete Test', value: totalCompleteCount, color: '#12B76A' },
    { label: 'Active Test', value: totalActiveCount, color: '#84caff' },
    { label: 'Inactive Test', value: totalInactiveCount, color: '#b9c0d4' },
    { label: 'Paused Test', value: totalPausedCount, color: '#ff9800' },
  ];

  const seriesData = data.map((item) => item.value);
  const labels = data.map((item) => item.label);
  const colors = data.map((item) => item.color);
  const totalRuns = seriesData.reduce((sum, val) => sum + val, 0); // Total value

  const [chartData, setChartData] = useState({
    series: [],
    options: {},
  });

  useEffect(() => {
    setChartData({
      series: seriesData,
      options: {
        chart: {
          type: 'donut',
          height: 350,
        },
        labels: labels,
        colors: colors,
        legend: {
          position: 'right',
          markers: {
            radius: 12,
          },
          formatter: function (label, opts) {
            return `${label}: ${opts.w.globals.series[opts.seriesIndex]}`;
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            return `${parseInt(val)}%`;
          },
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '65%',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '16px',
                  color: '#333',
                  fontWeight: 600,
                  offsetY: -10, // Adjust vertical position
                },
                value: {
                  show: true,
                  fontSize: '20px',
                  color: '#333',
                  fontWeight: 600,
                  offsetY: 10, // Adjust vertical position
                },
                total: {
                  show: true,
                  label: 'Total Runs',
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#666',
                  formatter: function () {
                    return `${totalRuns}`;
                  },
                },
              },
            },
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return `${val} Tests`;
            },
          },
        },
        stroke: {
          show: false,
        },
      },
    });
  }, [figures]); // Update chartData when figures change

  return (
    <div className="flex  flex-col justify-between gap-6 ">
      <div id="chart" className="  bg-white rounded-lg">
  

        <RechartsPieChart figures={figures} />

      </div>

      <div id="chart" className=" bg-white rounded-lg">
        <RadialBarChart />
      </div>


    </div>

    // <div className="flex flex-wrap justify-between items-start gap-2">
    //   {/* Radial Bar Chart Card */}
    //   <div className="flex-1 min-w-[300px] max-w-[50%] bg-white shadow-md rounded-md">
    //   <RechartsPieChart figures={figures} />
    //   </div>

    //   {/* Rechart Pie Chart Card */}
    //   <div className="flex-1 min-w-[300px] max-w-[50%] bg-white p-4 shadow-md rounded-md">
    //     <RadialBarChart/>
    //   </div>
    // </div>




  );
};

export default ApexChart;



