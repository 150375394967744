




import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

// Define time categories
const timeCategories = ['1 Day', '3 Day', '1 Week', '2 Week', '3 Week', '4 Week', '1 Month' ];

const categorizeVideos = (videos) => {
  const successData = new Array(timeCategories.length).fill(0);
  const failData = new Array(timeCategories.length).fill(0);

  const timeToIndex = (timeDiff) => {
    if (timeDiff <= 1) return 0; // 1 Day
    if (timeDiff <= 3) return 1; // 3 Day
    if (timeDiff <= 7) return 2; // 1 Week
    if (timeDiff <= 14) return 3; // 2 Week
    if (timeDiff <= 21) return 4; // 3 Week
    if (timeDiff <= 28) return 5; // 4 Week
    return 6; // 1 Month
  };

  const today = new Date();
  videos.forEach(({ createdTime, status }) => {
    // Parse the date string into a valid Date object
    const [datePart, timePart] = createdTime.split(', ');
    const [day, month, year] = datePart.split('/').map(Number);
    const videoDate = new Date(year, month - 1, day); // Month is zero-based

    const timeDiff = Math.ceil((today - videoDate) / (1000 * 60 * 60 * 24)); // Days difference
    const index = timeToIndex(timeDiff);

    if (status === 'success') successData[index]++;
    if (status === 'failed') failData[index]++;
  });

  return { successData, failData };
};


const ChartComponent = ({ videos ,footprintName }) => {
  console.log(footprintName,"thik")
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Pass',
        data: [],
      },
      {
        name: 'Fail',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        width: 3,
        curve: 'smooth',
      },
      title: {
        text: `Analysis of ${footprintName}`,
        align: 'center',
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
          color: '#333',
        },
      },
      grid: {
        show: true,
        borderColor: '#ddd',
      },
      xaxis: {
        categories: timeCategories,
        title: {
          text: 'Time',
          style: {
            color: '#555',
            fontSize: '14px',
          },
        },
      },
      yaxis: {
        title: {
          text: 'Count',
          style: {
            color: '#555',
            fontSize: '14px',
          },
        },
        min: 0,
        tickAmount: 5,
      },
      markers: {
        size: 8,
        hover: {
          size: 12,
        },
      },
      colors: ['#12B76A', '#F04438'],
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        floating: true,
        offsetY: -7,
        offsetX: 0,
        fontSize: '14px',
      },
    },
  });

  useEffect(() => {
    const { successData, failData } = categorizeVideos(videos);
    setChartData((prevData) => ({
      ...prevData,
      series: [
        { name: 'Pass', data: successData },
        { name: 'Fail', data: failData },
      ],
    }));
  }, [videos]);

  return (
    <div className="flex justify-center items-center bg-gray-50">
      <div className="w-full max-w-full sm:max-w-3xl lg:max-w-4xl bg-white rounded-lg p-2">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={350}
        />
      </div>
    </div>
  );
};

export default ChartComponent;


