


import React from "react";

import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import rocket from "../Images/skyrocket.png"
const Test_Landing_Card = ({ websiteId, isExtensionInstalled, svg }) => {

  return (
    <div className="container-2">
      <div className="wrapper w-full h-auto  min-h-[80vh] border border-layout-border  rounded-xl flex justify-center items-center flex-col bg-white">  {/** 70 vh to 90vh */}
        <div className="inner-wrapper">
          <div className="flex flex-col gap-2 items-center justify-center p-4 my-8 ">
                  {
                    svg  ? (
                      svg 
                    ) :    <div className="image w-[144px] h-full ">
                    <img src={rocket} alt="" className="w-full h-full object-cover" />
                  </div>
                  }
            {
              !svg && (
                <>
                  <div className="content text-center">
                    <h2 className="text-color-gray-light font-bold text-3xl">
                      Skyrocket your testing
                    </h2>
                    <p className="text-sm my-2">
                      Go ahead and create a test and schedule it.
                    </p>
                  </div>
                  <div className="flex items-center justify-center ">
                    <Button text="Create New Test" to={`/${websiteId}/test`} disabled={!isExtensionInstalled} />
                  </div></>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test_Landing_Card;
