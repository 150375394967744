import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Skill from "./Skill";
import { useNavigate } from "react-router-dom";
import FailToast from "../Toast/FailToast";
// import SuccessToast from "../Toast/SuccessToast";
import { baseUrl } from "../../config";
// import testtube from "../Images/TestTube.png";
import testtube from "../Images/Testtube logo.png";
import Website from "./Website";
import SettingButton from "../Button/SettingButton";

const Form = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [skill, setSkill] = useState(0);
  const [role, setRole] = useState(0);
  const [activeFail, setActiveFail] = useState(false);
  const [isValied, setIsValied] = useState(false)
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState("");
  const [websiteId , setWebsiteId] = useState()

  const [roleToggleStates, setRoleToggleStates] = useState({
    CXO: false,
    Product: false,
    Quality: false,
    Engineering: false,
    Others_role: false,
  });
  const [skillToggleStates, setSkillToggleStates] = useState({
    "Identify bugs": false,
    "QA Automation": false,
    "Sanity Testing": false,
    Design: false,
    Others_skill: false,
  });

  // const [formData, setFormData] = useState({
  //   mobile: "",
  //   role: [],
  //   skill: [],
  // });

  const [formData, setFormData] = useState({
    url: "",
    role: "",
    skill: "",
  });

  const FormTitles = ["Website", "Skill"];


  const [ErrorMessage, setErrorMessage] = useState("")

  const validateInput = (value) => {
    // Updated pattern to validate full URLs
    const urlPattern =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    const existingDomains = ["google.com", "example.com"]; // Add the list of domains already in use here

    try {
      const url = new URL(value); // Parse the input as a URL to extract the hostname

      if (existingDomains.includes(url.hostname)) {
        setErrorMessage(
          "That website is already part of your or someone else’s account."
        );
        setIsValied(false)
        return;

      } else if (!urlPattern.test(value)) {
        setErrorMessage("That doesn’t look like a website URL.");
        setIsValied(false)
        return;
      } else {
        setErrorMessage("");
        setIsValied(true)
        return;
      }
    } catch (error) {
      // If URL parsing fails, the input is not a valid URL
      setErrorMessage("That doesn’t look like a website URL.");
      setIsValied(false)
      return;
    }
  };



  const handleWebsiteChange = (e) => {
    validateInput(e.target.value)
    let value = e.target.value.trim();
    value = value
      .replace(/^https?:\/\/(www\.)?|^www\./, "")
      .replace(/\/+$/, "");
    const { name } = e.target;
    setFormData({ ...formData, [name]: value });

  };




  const addWebsite = async () => {



    console.log("Input value:", formData.url);
    const token = localStorage.getItem("Token");
    console.log("token data is done", token, "------------------------");


    if (token) {
      try {
        const response = await axios.post(
          `${baseUrl}/websites/`,
          {
            url: formData.url,


          },
          {
            headers: { authorization: `Bearer ${token}` },
          }
        );
        console.log("response0-----------------", response.data);
        // Store the latest added website ID in localStorage
        localStorage.setItem("latestWebsiteId", response.data?.website?._id);
        setWebsiteId(response.data?.website?._id)
      } catch (error) {
        console.log("error", error.response.data.msg)
        setLoading(false)


      }
    }


  };



  // const handleFilterTrueValues = () => {
  // const filteredSkills = Object.entries(skillToggleStates)
  //   .filter(([key, value]) => value === true)
  //   .map(([key]) => key); // Extracting just the keys

  // setSkillCount(filteredSkills.length);
  // console.log("filterSkills", filteredSkills.length);

  // const filteredRole = Object.entries(roleToggleStates)
  //   .filter(([key, value]) => value === true)
  //   .map(([key]) => key); // Extracting just the keys
  // setRoleCount(filteredRole.length());
  // console.log("filterRole", filteredRole.length);
  // return { filteredRole, filteredSkills };
  // };

  const handleFilterTrueValues = () => {
    // const filteredRoles = Object.values(roleToggleStates).filter(
    //   (value) => value
    // ).length;
    // const filteredSkills = Object.values(skillToggleStates).filter(
    //   (value) => value
    // ).length;

    const filteredSkills = Object.entries(skillToggleStates)
      .filter(([key, value]) => value === true)
      .map(([key]) => key); // Extracting just the keys

    setSkill(filteredSkills);
    // console.log("filterSkills", filteredSkills.length);

    const filteredRole = Object.entries(roleToggleStates)
      .filter(([key, value]) => value === true)
      .map(([key]) => key); // Extracting just the keys
    setRole(filteredRole);
    // console.log("filterRole", filteredRole.length);
    // return { filteredRole, filteredSkills };

    // setRoleCount(filteredRoles);
    // setSkillCount(filteredSkills);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // console.log("role", role);
  //   // console.log("skill", skill);
  //   // console.log("formData", formData);

  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const userId = localStorage.getItem("userId");
    

    if (!formData.url) {
      setActiveFail(true)
      setValue("Enter the website Url")
      setLoading(false)
      return;
    }

    else if (!formData.role && !formData.skill) {
      setActiveFail(true)
      setValue("Select the role and skill")
      setLoading(false)

      return;
    }
    else {
      await addWebsite()
      if (userId) {

        try {
          const payload = {
            skill: formData.skill,
            role: formData.role,
            user: userId,
          };
          const response = await axios.post(`${baseUrl}/onboard/`, payload);

          console.log("response", response.data);
          // alert(response.data.msg);
          // alert("onboarding completed !!");
          setActiveSuccess(true);
          setValue("onboarding completed !!");
          setLoading(false)
        } catch (error) {
          // alert(error.response.data.msg);
          setLoading(false)
          setActiveFail(true);
          setValue(error.response.data.msg);

        }
      }
    }
  };

  useEffect(() => {
    handleFilterTrueValues();
  }, [roleToggleStates, skillToggleStates]);

  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 3000);
    }
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
        navigate(`/${websiteId}/dashboard`);
      }, );
    }
  }, [activeFail, activeSuccess]);

  // const validatePage = () => {
  //   if (page === 0) {
  //     return role.length > 1;
  //   } else if (page === 1) {
  //     return skill.length > 1;
  //   } else {
  //     return formData.mobile.length >= 10;
  //   }
  // };

  const validatePage = () => {
    if (page === 0) {
      return isValied;
    } else if (page === 1) {
      return formData.role != "" && formData.skill != ""
    }
  };

  const PageDisplay = () => {
    if (page === 0) {
      return (

        <Website
          formData={formData}
          ErrorMessage={ErrorMessage}
          handleWebsiteChange={handleWebsiteChange}
          setFormData={setFormData}
        />
      );
    } else if (page === 1) {
      return (


        <Skill
          formData={formData}
          setFormData={setFormData}
        />
      );
    }
  };

  return (
    <>
      {activeFail && (
        <FailToast
          errorValue={value}
          activeFail={activeFail}
          setActiveFail={setActiveFail}
        />
      )}

      {/* <div class="flex items-center justify-center min-h-screen px-6 lg:px-8">
        <div class="max-w-2xl w-full h-[600px] shadow-lg rounded-2xl ring-1 ring-gray-200 flex flex-col">
          <div class="p-8 sm:p-10 flex-grow">
            <div class="relative h-2 rounded-full overflow-hidden bg-[#E3E3E3] ">
              <div
                class="absolute top-0 bottom-0 left-0 rounded-full bg-[#2AB930]  "
                style={{
                  width: page === 0 ? "33.3%" : page == 1 ? "66.6%" : "100%",
                }}
              ></div>
            </div>

            <div className="mt-4">
              <img src={testtube} alt="testtube logo" className="h-12" />
            </div>

            <p class="mt-4  leading-7 text-color-gray-light font-semibold text-xl ">
              Let’s get started
            </p>

            <p class="text-sm font-normal text-color-gray-light">
              {page === 0
                ? "Select 2 roles that describe you the best?"
                : page === 1
                ? "What can we help you with? Select any two"
                : "Please provide your phone number for receiving test reports."}
            </p>
            <div className="">{PageDisplay()}</div>

            <div class="flex gap-4 justify-end p-8 mt-auto">
              {page == 0 ? (
                ""
              ) : (
                <button>
                  <a
                    class="px-3 py-2 cursor-pointer min-w-[80px] text-center text-black-600   rounded-lg   focus:outline-none focus:ring"
                    onClick={() => {
                      setPage((currPage) => currPage - 1);
                    }}
                  >
                    Back
                  </a>
                </button>
              )}
              {page === FormTitles.length - 1 ? (
                <button
                  className={`px-3 py-2 min-w-[80px] text-center border rounded-lg focus:outline-none focus:ring ${
                    validatePage()
                      ? "text-white bg-btn-gradient-auth active:text-white"
                      : "text-color-gray-light-400 bg-gray-300 cursor-not-allowed"
                  }`}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  disabled={!validatePage()}
                >
                  Done
                </button>
              ) : (
                <button
                  className={`px-3 py-2 min-w-[80px] text-center border rounded-lg focus:outline-none focus:ring ${
                    validatePage()
                      ? "text-white bg-btn-gradient-auth active:text-white"
                      : "text-color-gray-light-400 bg-gray-300 cursor-not-allowed"
                  }`}
                  onClick={() => {
                    setPage((currPage) => currPage + 1);
                    handleFilterTrueValues();
                  }}
                  disabled={!validatePage()}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </div> */}
      <div class="flex  justify-center mx-24 ">
        <div class="   w-[100vw]   h-[100vh] rounded-lg flex flex-col bg-white   border border-layout-border shadow-[0px_1px_2px_0px_#0000000D]  py-6 px-10">

          <div class="flex-grow">
            {/* <div class="relative h-2 rounded-full overflow-hidden bg-[#E3E3E3]">
              <div
                class="absolute top-0 bottom-0 left-0 rounded-full bg-onboard-progress "
                style={{
                  width: page === 0 ? "33.3%" : page == 1 ? "66.6%" : "100%"
                }}
              ></div>
            </div> */}
            {/* <div className="relative h-2 rounded-full overflow-hidden">
              <div
                className="absolute inset-0"
                style={{
                  background: "linear-gradient(90deg, #282828 0%, #282828 28%, #E3E3E3 29%, #E3E3E3 100%)",
                }}
              ></div>

              <div
                className="absolute top-0 bottom-0 left-0 bg-[#282828] rounded-full"
                style={{
                  width: page === 0 ? "33.3%" : page === 1 ? "66.6%" : "100%",
                }}
              ></div>
            </div> */}

            {/* <div class="mt-4">
              <img src={testtube} alt="testtube logo" class="h-12" />
            </div> */}


            {/* <div class=" text-color-gray-light font-semibold text-3xl">
              {page === 0
                ? "Add your Website"
                : page === 1
                  ? "What can we help you with? Select any two"
                  : "Please provide your phone number for receiving test reports."}
            </div>
            */}

            <div class=" text-color-gray-light font-semibold text-3xl">
              {page === 0
                ? "Add your Website"
                : "Tell us who are you"}
            </div>

            <p class="text-sm font-medium text-color-gray-light pt-1.5">
              {page === 0
                ? "To create tests for your website, you need to add your website in your account."
                : "This will allow us to build better testing capabilities."}
            </p>

            <div>{PageDisplay()}</div>
          </div>

          <div class="flex gap-4 justify-end p-2 mt-auto">
            {page == 0 ? (
              ""
            ) : (
              // <button>
              //   <a
              //     class="px-3 py-2 text-xs font-normal cursor-pointer min-w-[80px] text-center text-black-600 rounded-lg focus:outline-none focus:ring"
              //     onClick={() => {
              //       setPage((currPage) => currPage - 1);
              //     }}
              //   >
              //     Back
              //   </a>
              // </button>
              <SettingButton text={"Back"} bgColor='#FFFFFF' textColor="color-gray-light" hoverColor=''
                onClick={() => {
                  setPage((currPage) => currPage - 1);
                }}

              />
            )}

            {page === FormTitles.length - 1 ? (
              // <button
              //   className={`px-3 py-2 min-w-[80px] text-center text-base text-bold border rounded-lg focus:outline-none focus:ring ${validatePage()
              //     ? "text-white bg-button-bg hover:bg-button-hover active:text-white"
              //     : "text-color-gray-light-400 bg-gray-300 cursor-not-allowed"
              //     }`}
              //   onClick={(e) => {
              //     handleSubmit(e);
              //   }}
              //   disabled={!validatePage()}
              // >
              //   Done
              // </button>
              <SettingButton text={"Done"} onClick={(e) => handleSubmit(e)} loading={loading} disabled={!validatePage()} />
            ) : (
              // <button
              //   className={`px-3 py-2 min-w-[80px] text-bold text-base text-center border rounded-lg focus:outline-none focus:ring ${validatePage()
              //     ? "text-white bg-button-bg hover:bg-button-hover active:text-white"
              //     : "text-color-gray-light-400 bg-gray-300 cursor-not-allowed"
              //     }`}
              //   onClick={() => {
              //     setPage((currPage) => currPage + 1);
              //     handleFilterTrueValues();
              //   }}
              //   disabled={!validatePage()}
              // >
              //   Next
              // </button>
              <SettingButton text={"Next"} onClick={() => {
                setPage((currPage) => currPage + 1);
              }}
                disabled={!validatePage()} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
