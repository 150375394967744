

import React from "react";
import SettingSidebar from "../Sidebar/SettingSidebar";
// import Navbar from "../Navbar/Navbar";

import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import Nav from "../Navbar/Nav";

const SettingLayout = () => {
  return (
    <div className="flex flex-col min-h-screen bg-layout">
      {/* Navbar at the top */}
      {/* <Navbar /> */}
      <Nav/>

      {/* Content area with sidebar and main content */}
      <div className="outer-section mx-20 py-6 gap-6 flex flex-1 overflow-hidden">
        {/* Sidebar specific to settings */}
        <SettingSidebar />

        {/* Main content area */}
        <main className="flex-1  overflow-y-auto">
          <Outlet /> {/* Render child routes dynamically */}
        </main>
      </div>
      <Footer/>
    </div>
  );
};

export default SettingLayout;
