import React from 'react'

const Footer = () => {
    return (
        <>
            <div className="bg-primary-button ">


                {/** mx-16 to mx-20 */}
                <div className="footer-section flex justify-between items-center mx-20 py-4 xl:py-8">
                    <div className="left-section text-white flex flex-col gap-2 xl:gap-4">
                        <div className="logo-section text-lg">
                            TestTube
                        </div>
                        <p className='text-xs text-footer'>© 2024 TestTube. A More Tasks Company. All rights reserved.</p>


                    </div>

                    <div className="right-section">
                        <ul className="links flex justify-between items-center gap-6 text-white">
                            <li className="list-items cursor-pointer hover:text-link transition ease-out duration-300">
                                <a href="https://testtube.io/contact-us/" target="_blank" rel="noopener noreferrer">Contact Us</a>
                            </li>
                            <li className="list-items cursor-pointer hover:text-link transition ease-out duration-300">
                                <a href="https://testtube.io/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policies</a>
                            </li>
                            <li className="list-items cursor-pointer hover:text-link transition ease-out duration-300">
                                <a href="https://testtube.io/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Footer